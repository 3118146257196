/**
 * Definition of providers.
 * Adapted from https://github.com/leaflet-extras/leaflet-providers/blob/master/leaflet-providers.js
 */

const attributionOpenStreetMap =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>';
export const TileProviders = {
  OpenStreetMap: {
    url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
    options: {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 18,
    },
  },
  GoogleMap: {
    url: 'https://www.google.cn/maps/vt?lyrs=m@199&gl=cn&x={x}&y={y}&z={z}',
    options: {
      attribution: 'Google Maps',
      maxZoom: 22,
    },
    variants: {
      GoogleMap: {
        url: 'https://www.google.cn/maps/vt?lyrs=m@199&gl=cn&x={x}&y={y}&z={z}',
        options: {
          attribution: 'Google Maps',
          maxZoom: 22,
        },
      },
      GoogleHybrid: {
        url: 'https://www.google.cn/maps/vt?lyrs=s,h@199&gl=cn&x={x}&y={y}&z={z}',
        options: {
          attribution: 'Google Hybrid',
          maxZoom: 22,
        },
      },
      GoogleTerrain: {
        url: 'https://www.google.cn/maps/vt?lyrs=p&gl=cn&x={x}&y={y}&z={z}',
        options: {
          attribution: 'Google Terrain',
          maxZoom: 22,
        },
      },
      GoogleSatellite: {
        url: 'https://www.google.cn/maps/vt?lyrs=s&gl=cn&x={x}&y={y}&z={z}',
        options: {
          attribution: 'Google Satellite',
          maxZoom: 22,
        },
      },
      GoogleRoadmap: {
        url: 'https://www.google.cn/maps/vt?lyrs=m&gl=cn&x={x}&y={y}&z={z}',
        options: {
          attribution: 'Google Roadmap',
          maxZoom: 22,
        },
      },
      GoogleTerrainHybrid: {
        url: 'https://www.google.cn/maps/vt?lyrs=p,h@199&gl=cn&x={x}&y={y}&z={z}',
        options: {
          attribution: 'Google Terrain Hybrid',
          maxZoom: 22,
        },
      },
      GoogleTerrainRoadmap: {
        url: 'https://www.google.cn/maps/vt?lyrs=p,m@199&gl=cn&x={x}&y={y}&z={z}',
        options: {
          attribution: 'Google Terrain Roadmap',
          maxZoom: 22,
        },
      },
      GoogleRoadmapHybrid: {
        url: 'https://www.google.cn/maps/vt?lyrs=m,h@199&gl=cn&x={x}&y={y}&z={z}',
        options: {
          attribution: 'Google Roadmap Hybrid',
          maxZoom: 22,
        },
      },
      GoogleRoadmapTerrain: {
        url: 'https://www.google.cn/maps/vt?lyrs=m,p@199&gl=cn&x={x}&y={y}&z={z}',
        options: {
          attribution: 'Google Roadmap Terrain',
          maxZoom: 22,
        },
      },
      GoogleRoadmapTerrainHybrid: {
        url: 'https://www.google.cn/maps/vt?lyrs=m,p,h@199&gl=cn&x={x}&y={y}&z={z}',
        options: {
          attribution: 'Google Roadmap Terrain Hybrid',
          maxZoom: 22,
        },
      },
      GoogleTransit: {
        url: 'https://a.tile.openstreetmap.de/{z}/{x}/{y}.png',
        options: {
          attribution: 'Google Transit',
          maxZoom: 18,
        },
      },
    },
  },
  StadiaHidric: {
    url: 'https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg',
    attribution:
      '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
  },
  BaiduMap: {
    url: 'http://online{s}.map.bdimg.com/onlinelabel/?qt=tile&x={x}&y={y}&z={z}&styles=pl&scaler=1&p=1',
    options: {
      maxZoom: 22,
      attribution:
        '&copy; <a href="http://map.baidu.com/">Baidu</a> ' +
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    },
    variants: {
      BaiduMap: {
        url: 'http://online{s}.map.bdimg.com/onlinelabel/?qt=tile&x={x}&y={y}&z={z}&styles=pl&scaler=1&p=1',
        options: {
          maxZoom: 22,
          attribution:
            '&copy; <a href="http://map.baidu.com/">Baidu</a> ' +
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        },
      },
      BaiduSatellite: {},
    },
  },
  TileProviders: {
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    options: {
      maxZoom: 22,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    },
    variants: {
      Mapnik: {},
      BlackWhite: {
        url: 'https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png',
        options: {
          attribution: '' + attributionOpenStreetMap + '',
        },
      },
      DE: {
        url: 'https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png',
        options: {
          maxZoom: 22,
        },
      },
      France: {
        url: 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
        options: {
          maxZoom: 22,
          attribution: '&copy; OpenStreetMap France | ' + attributionOpenStreetMap + '',
        },
      },
      HOT: {
        url: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
        options: {
          attribution:
            '' +
            attributionOpenStreetMap +
            ', ' +
            'Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> ' +
            'hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>',
        },
      },
    },
  },
  OpenTopoMap: {
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    options: {
      maxZoom: 22,
      attribution:
        'Map data: ' +
        attributionOpenStreetMap +
        ', <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    },
  },
  SATSTADIA: {
    url: 'https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.jpg',

    attribution:
      '&copy; CNES, Distribution Airbus DS, © Airbus DS, © PlanetObserver (Contains Copernicus Data) | &copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  },
  Stadia: {
    url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png',
    options: {
      maxZoom: 22,
      attribution:
        attributionOpenStreetMap +
        '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
    },
    variants: {
      AlidadeSmooth: {
        url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png',
      },
      AlidadeSmoothDark: {
        url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
      },
      OSMBright: {
        url: 'https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png',
      },
      Outdoors: {
        url: 'https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png',
      },
    },
  },
  Esri_WorldImagery: {
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    attribution:
      'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
  },
  // Thunderforest: {
  //   url: 'https://{s}.tile.thunderforest.com/{variant}/{z}/{x}/{y}.png?apikey={apikey}',
  //   options: {
  //     attribution:
  //       '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, ' +
  //       attributionOpenStreetMap +
  //       '',
  //     variant: 'cycle',
  //     apikey: '',
  //     maxZoom: 22,
  //   },
  //   variants: {
  //     OpenCycleMap: 'cycle',
  //     Transport: {
  //       options: {
  //         variant: 'transport',
  //       },
  //     },
  //     TransportDark: {
  //       options: {
  //         variant: 'transport-dark',
  //       },
  //     },
  //     SpinalMap: {
  //       options: {
  //         variant: 'spinal-map',
  //       },
  //     },
  //     Landscape: 'landscape',
  //     Outdoors: 'outdoors',
  //     Pioneer: 'pioneer',
  //     MobileAtlas: 'mobile-atlas',
  //     Neighbourhood: 'neighbourhood',
  //   },
  // },

  CyclOSM: {
    url: 'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png',
    options: {
      maxZoom: 22,
      attribution:
        '<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: ' +
        attributionOpenStreetMap +
        '',
    },
  },
  sat: {
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    attribution:
      'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
  },
  top: {
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  },
  STREET: {
    url: 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  USGS: {
    url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}',
    options: {
      maxZoom: 22,
      attribution: 'Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>',
    },
    variants: {
      USTopo: {},
      USImagery: {
        url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}',
      },
      USImageryTopo: {
        url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}',
      },
    },
  },
};

// export const TileProviders = () => {
// 	return myTileProviders;
// }

// export default TileProviders;
