import P from 'prop-types';
const StreetView = ({ coordinates }) => {
  return (
    <iframe
      title='Map'
      src={`https://maps.google.com/maps?q=${coordinates.lat},${coordinates.lng}&layer=c&cbll=${coordinates.lat},${coordinates.lng}&cbp=11,0,0,0,0&output=svembed`}
      width='100%'
      height='100%'
      style={{ border: 0 }}
      allowFullScreen
      loading='lazy'
      referrerPolicy='no-referrer-when-downgrade'
    ></iframe>
  );
};
export default StreetView;
StreetView.propTypes = {
  coordinates: P.shape({
    lat: P.number,
    lng: P.number,
  }),
};
