/**

Verifica se um determinado valor está presente em um array de objetos.
*@param {Array} array - O array de objetos a ser verificado.
*@param {string} param - O parâmetro a ser verificado nos objetos do array.
*@param {*} value - O valor a ser buscado no parâmetro especificado.
*@returns {boolean} - Retorna true se o valor for encontrado, caso contrário, retorna false.
*/

export const HasValue = (array, param, value) => {
  if (array && array.length !== 0) {
    for (var i = 0; i < array.length; i++) {
      if (param in array[i] && array[i][param] === value) {
        return true;
      }
    }
    return false;
  }
};
