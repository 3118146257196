import { useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { apiUrl } from '../../config/modules/api';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

const Auth0Valid = () => {
  // eslint-disable-next-line no-unused-vars
  const [text, setText] = useState('Loading...');
  const [userID, setUserID] = useState(null);
  const [tokenUser, setTokenUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const location = useLocation();
  const params = useParams();
  const [valid, setValid] = useState(false);

  var access_token = location.search.split('?')[1].split('=')[1].split('&')[0];

  useEffect(() => {
    if (access_token) {
      var decode = jwt_decode(access_token);

      localStorage.setItem('userId', decode.sub);
      setUserID(decode.sub);
    }
  }, [access_token]);
  debugger;
  useEffect(() => {
    fetch(`${apiUrl}/api/auth/${params.providerName ?? 'auth0'}/callback${location.search}`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        // Successfully logged with Strapi
        // Now saving the jwt to use it for future authenticated requests to Strapi
        localStorage.setItem('tk_uac', res.jwt);
        setTokenUser(res.jwt);
        Cookies.set('tk_uac', res.jwt);
        localStorage.setItem('username', res.user.username);
        localStorage.setItem('userData', JSON.stringify(res.user));
        setUserData(res.user);

        setText('You have been successfully logged in. You will be redirected in a few seconds...');

        // Redirect to homepage after 3 sec
      })
      .catch((err) => {
        console.log({ err });
        setText('An error occurred, please see the developer console.');
      });
  }, [location.search, params.providerName]);

  useEffect(() => {
    if (tokenUser && userID) {
      getUserApiToken(tokenUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenUser, userID]);
  console.log(userID, tokenUser, userData);
  debugger;
  async function getUserApiToken(token) {
    await import('../../config/modules/api').then(({ api }) =>
      api
        .get('/api/user-api-tokens', {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            populate: '*',
            'filters[user_id][$eq]': userID,
          },
        })
        .then(async (r) => {
          if (r.data.data && userData) {
            await api
              .get(`/api/users/${userData.id}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                params: {
                  'populate[0]': 'user_profile',
                },
              })
              .then(async (ress) => {
                console.log({ userEncontrado: ress.data });
                if (ress?.data && ress?.data?.user_profile?.id) {
                  console.log('USUÁRIO JÁ POSSUI PERFIL');
                  setTimeout(() => {
                    window.location.href = '/';
                  }, 3000);
                } else {
                  await api
                    .put(
                      `/api/users/${ress?.data.id}`,
                      {
                        user_profile: r.data.data[0]?.attributes?.user_profile?.data?.id,
                      },

                      {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                          'populate[0]': 'user_profile',
                        },
                      },
                    )
                    .then(
                      () => setValid(true),
                      // setTimeout(() => {
                      //   window.location.href = '/';
                      // }, 3000),
                    )
                    .catch((e) => console.log(e));
                }
              });
          }
        })
        .catch((e) => console.log(e)),
    );
  }
  return valid ? JSON.parse({ token: tokenUser }) : false;
};

export default Auth0Valid;
