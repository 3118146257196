import React, { Fragment, useState } from 'react';
import P from 'prop-types';
import { MapContainer } from 'react-leaflet';

import { Markers } from './modules/Markers';
import { KmlLayer } from './modules/KmlLayers';
import { LayersControlCustom } from './modules/LayersControlCustom';

import 'leaflet/dist/leaflet.css';
import 'react-leaflet-fullscreen/styles.css';
import { FullscreenControl } from 'react-leaflet-fullscreen';
// import { EventMap } from './modules/EventMap';

// import { EventMap } from './modules/EventMap';
/**
 *
 * @param {Array} markPositions - Array of positions to be marked on the map
 * @returns
 */

const Map = ({
  data,
  markPositions,
  id,
  sistemaPositions,
  remotasPositions,
  height,
  width,
  CustomLayers,
}) => {
  const [mapCenter] = useState([-23.5569152, -46.7992576]);

  return (
    <MapContainer
      id={id}
      center={mapCenter}
      zoom={13}
      style={{ minHeight: '100%', width: '100%' }}
      zoomControl={false}
      minZoom={2}
      maxZoom={22}
      zoomAnimation
      boxZoom
    >
      <LayersControlCustom CustomLayers={CustomLayers} />

      {markPositions && markPositions?.length !== 0 && <Markers positions={markPositions} />}
      {remotasPositions && remotasPositions?.length !== 0 && (
        <Markers positions={remotasPositions} />
      )}
      {sistemaPositions && sistemaPositions?.length !== 0 && (
        <Markers positions={sistemaPositions} />
      )}

      {data?.kmlGeral?.length !== 0 &&
        data?.kmlGeral?.map((kml) => {
          return (
            <Fragment key={kml?.id}>
              <KmlLayer kmlUrl={kml} />
            </Fragment>
          );
        })}
      {data?.kmlRegistros?.length !== 0 &&
        data?.kmlRegistros?.map((kml) => {
          return (
            <Fragment key={kml?.id}>
              <KmlLayer kmlUrl={kml} />
            </Fragment>
          );
        })}
      {data?.kmlEquipamentos?.length !== 0 &&
        data?.kmlEquipamentos?.map((kml) => {
          return (
            <Fragment key={kml?.id}>
              <KmlLayer kmlUrl={kml?.url} />
            </Fragment>
          );
        })}
      {data?.kmlSetores?.length !== 0 &&
        data?.kmlSetores?.map((kml) => {
          return (
            <Fragment key={kml?.id}>
              <KmlLayer kmlUrl={kml} />
            </Fragment>
          );
        })}
      {data?.kmlDistrito?.length !== 0 &&
        data?.kmlDistrito?.map((kml) => {
          return (
            <Fragment key={kml?.id}>
              <KmlLayer kmlUrl={kml} />
            </Fragment>
          );
        })}
      {data?.kmlBairro?.length !== 0 &&
        data?.kmlBairro?.map((kml) => {
          return (
            <Fragment key={kml?.id}>
              <KmlLayer kmlUrl={kml} />
            </Fragment>
          );
        })}
      {data?.kmlRegistros?.length !== 0 &&
        data?.kmlRegistros?.map((kml) => {
          return (
            <Fragment key={kml?.id}>
              <KmlLayer kmlUrl={kml} />
            </Fragment>
          );
        })}
      <FullscreenControl position='topright' />
      {/* <EventMap /> */}
    </MapContainer>
  );
};

export default Map;

Map.propTypes = {
  markPositions: P.array,
  sistemaPositions: P.array,
  remotasPositions: P.array,
  height: P.oneOfType([P.string, P.number]),
  width: P.oneOfType([P.string, P.number]),
  CustomLayers: P.node,
  data: P.shape({
    kmlGeral: P.array,
    kmlEquipamentos: P.array,
    kmlSetores: P.array,
    kmlDistrito: P.array,
    kmlBairro: P.array,
    kmlRegistros: P.array,
  }),
  id: P.string,
};
