import React, { useEffect, useState } from 'react';
import P from 'prop-types';
import './styles/switchCheckbox.css';

/**
 *
 * @param {function} event - Função Callback determine qual a função que receberá o retorno da alteração do checkbox
 * @param {boolean} defaultChecked - Boolean - define se o checkbox inicializará com status checked=true
 * @param {string} title - String - Valor do titulo do label que aparece ao passar o mouse
 * @param {string} color - String - "success", "warning" , "primary" , "info" , "danger"
 * @example success | warning | primary | info | danger
 * @example  <SwitchCheckbox
                      size={'md'}
                      event={setStatuscheck}
                      title='Alterar status'
                    />

 * @returns
 */
const SwitchCheckbox = (props) => {
  const [checked, setchecked] = useState(props.defaultChecked);

  const size = {
    width: props.size === 'sm' ? 40 : props.size === 'md' ? 50 : props.size === 'lg' ? 60 : 60,
    height: props.size === 'sm' ? 20 : props.size === 'md' ? 25 : props.size === 'lg' ? 35 : 35,
  };
  useEffect(() => {
    if (props.defaultChecked) setchecked(props.defaultChecked);
  }, [props.defaultChecked]);

  return (
    <>
      <label className='switch' title={props.title} style={size}>
        <input
          id={props.id}
          type='checkbox'
          onChange={(e) => props.event(e.target?.checked)}
          defaultChecked={checked}
          onClick={props.onClick}
        />{' '}
        <span className={`slider pt-1 round ${props.color ? `slider-${props.color}` : ''}`}></span>{' '}
      </label>
      {props.label && (
        <label
          htmlFor={props.id}
          className={`text-${props.size || 'sm'} m-2 switch-label text-capitalize`}
        >
          {props.label}
        </label>
      )}
    </>
  );
};

SwitchCheckbox.propTypes = {
  event: P.func,
  defaultChecked: P.bool,
  title: P.string,
  size: P.oneOfType([P.number, P.string]),
  color: P.string,
  label: P.string,
  id: P.string,
  onClick: P.func,
};

export default SwitchCheckbox;
