import { lazy } from 'react';

export const pagesMap = {
  404: lazy(() => import('../../pages/Page404')),
  home: lazy(() => import('../pages/home')),
  distritos: lazy(() => import('../pages/distritos')),
  conexoes: lazy(() => import('../pages/adm/Connections/Conexoes')),
  usuarios: lazy(() => import('../pages/adm/Usuarios')),
  'ordem-de-servicos': lazy(() => import('../pages/gestao-servicos/')),
  'painel-macro': lazy(() => import('../pages/relatorios/PainelMacromedidor')),
  'painel-reservacao': lazy(() => import('../pages/relatorios/PainelReservacao')),
  'medidor-vazao': lazy(() => import('../pages/relatorios/medidor-vazao/MedidorVazao')),
};
