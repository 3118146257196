import React from 'react';
import P from 'prop-types';

const MenuTreeview = (props) => {
  return <ul className='nav nav-treeview'>{props.children}</ul>;
};

export default MenuTreeview;
MenuTreeview.propTypes = {
  children: P.node.isRequired,
};
