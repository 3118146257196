import React from 'react';
import { Suspense, lazy } from 'react';
import Layout from './layout/Layout';
import Loading from './components/Loading';
import Forgot from './pages/forgot/Forgot';
import ConfirmLogin from './pages/login/confirm/ConfirmLogin';
import CompanyProvider from './store/providers/CompanyProvider';
import Auth0Valid from './pages/login/auth0Valid';
import PageLogout from './pages/login/PageLogout';
import Template from './v1/Template';
import PrivateRouter from './main/privateRoute';

const Login = lazy(() => import('./pages/login/Login'));
const LoginVerification = lazy(() => import('./pages/login/LoginVerification'));
const Signup = lazy(() => import('./pages/register/signup'));
const Page404 = lazy(() => import('./pages/Page404'));
// const Teste = lazy(() => import('./pages/testes/Teste'));

const routes = [
  {
    path: '/',
    element: <Layout />,
  },
  {
    path: '/:slug',
    element: <Layout />,
  },
  {
    path: ':slug/:params/:value?',
    element: <Layout />,
  },
  {
    path: ':slug/:params/:value/:action/:actionvalue',
    element: <Layout />,
  },
  {
    path: ':slug/:params/:value/:action/:actionvalue?/:value?',
    element: <Layout />,
  },
  {
    path: '/login',
    element: (
      <Suspense fallback={<Loading />}>
        <CompanyProvider>
          <Login />
        </CompanyProvider>
      </Suspense>
    ),
  },
  {
    path: '/codverifield',
    element: (
      <Suspense fallback={<Loading />}>
        <LoginVerification />
      </Suspense>
    ),
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/forgot-password',
    element: <Forgot />,
  },
  {
    path: '/forgot-password/:reset',
    element: <Forgot />,
  },
  { path: '/confirm/login', element: <ConfirmLogin /> },
  { path: 'auth/callback', element: <Auth0Valid /> },
  { path: 'auth/logout', element: <PageLogout /> },
  {
    path: '/v1/:slug?/:params?/:value?',
    element: (
      <PrivateRouter>
        <Template />
      </PrivateRouter>
    ),
  },

  {
    path: '*',
    element: (
      <Layout error={404}>
        <Page404 />
      </Layout>
    ),
  },
];

export default routes;
