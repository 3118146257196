import * as types from '../reducers/ContentTypes';
import { api } from '../modules/api';

export const loadLogin = async (dispatch, url, options) => {
  const contentRaw = await api.get(url, options);
  const content = contentRaw.data;
  return () => dispatch({ type: types.LOGIN_SUCCESS, payload: content });
};
export const postLogin = async (dispatch, url, options) => {
  const contentRaw = await api.post(url, options);
  const content = contentRaw.data;

  return () => dispatch({ type: types.LOGIN_SUCCESS, payload: content });
};
