import React, { useContext, useEffect, useMemo, useState } from 'react';

import Map from '../../../../components/Leaflat/NewMap/Map';

import { Ctx } from '../../../../store/ctx/Ctx';
import DefaultMap from '../../../../components/Leaflat/NewMap/modules/DefaultMap';

import './styles.css';
import {
  DistritoMapModel,
  HomeMapModelEquipamentos,
  RegistroFechamentoDescargaMapModel,
  SetorMapModel,
  SistemaMapModel,
} from '../../model/HomeMap.model';

import { CustomLayer } from './CustomLayer/CustomLayer';
const MapHome = () => {
  const [equipamentoPosition, setEquipamentoPosition] = useState([]);
  const [kmlSetores, setKmlSetores] = useState([]);
  const [kmlDistrito, setKmlDistrito] = useState([]);
  const [kmlRegistros, setKmlRegistros] = useState([]);
  const [kmlRegistrosDescarga, setKmlRegistrosDescarga] = useState([]);
  const [sistemaPositions, setSistemaPositions] = useState([]);
  const [RemotaPositions, setRemotaPositions] = useState([]);
  const ctx = useContext(Ctx);

  const {
    captacoesList,
    imovelList,
    companySelected,
    tipoSelectedMap,
    sistemaList,
    equipamentoList,
    setorList,
    distritoList,
    unidadeRemotaList,
    reload,
  } = ctx.props.data;

  //  Sistemas
  useEffect(() => {
    setSistemaPositions([]);
    if (sistemaList?.length !== 0 && tipoSelectedMap.length === 0) {
      sistemaList.forEach((_sistema) => {
        const sistema = new SistemaMapModel(_sistema);
        setSistemaPositions((prev) => [...prev, sistema]);
      });
    } else {
      setSistemaPositions([]);
    }
  }, [sistemaList, tipoSelectedMap]);
  // Equipamentos
  useEffect(() => {
    setEquipamentoPosition([]);
    if (equipamentoList && equipamentoList?.length !== 0) {
      equipamentoList.forEach((_equipamento) => {
        const equipamento = new HomeMapModelEquipamentos(_equipamento);
        tipoSelectedMap.includes(
          equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela,
        ) && setEquipamentoPosition((prev) => [...prev, equipamento]);
      });
    }
  }, [equipamentoList, tipoSelectedMap]);

  useEffect(() => {
    setKmlSetores([]);
    if (setorList?.length !== 0) {
      setorList.forEach((_setor) => {
        const setor =
          _setor?.attributes?.setor_kml?.data?.attributes?.url && new SetorMapModel(_setor);

        _setor?.attributes?.setor_kml?.data?.attributes?.url &&
          setKmlSetores((prev) => [...prev, setor]);
      });
    }
  }, [setorList, reload]);
  useEffect(() => {
    setKmlDistrito([]);
    if (distritoList?.length !== 0) {
      distritoList.forEach((_distrito) => {
        const distrito =
          _distrito?.attributes?.dmc_kml?.data?.attributes?.url && new DistritoMapModel(_distrito);

        _distrito?.attributes?.dmc_kml?.data?.attributes?.url &&
          setKmlDistrito((prev) => [...prev, { ...distrito }]);
      });
    }
  }, [distritoList, reload]);

  // Registro de Fechamento
  useEffect(() => {
    setKmlRegistros([]);
    if (
      equipamentoList &&
      equipamentoList?.length !== 0 &&
      tipoSelectedMap === 'detalhe_registrofechamento'
    ) {
      equipamentoList.forEach((_equipamento) => {
        if (
          _equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
            'detalhe_registrofechamento' &&
          _equipamento?.attributes?.detalhe_registrofechamento?.data?.attributes?.rf_kml?.data
        ) {
          const equipamento = new RegistroFechamentoDescargaMapModel(
            _equipamento,
            'primary-default',
          );
          setKmlRegistros((prev) => [...prev, equipamento]);
        }
      });
    }
  }, [equipamentoList, tipoSelectedMap]);
  // Registro de Descarga
  useEffect(() => {
    setKmlRegistrosDescarga([]);
    if (
      equipamentoList &&
      equipamentoList?.length !== 0 &&
      tipoSelectedMap === 'detalhe_registrodescarga'
    ) {
      equipamentoList.forEach((_equipamento) => {
        if (
          _equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
            'detalhe_registrodescarga' &&
          _equipamento?.attributes?.detalhe_registrodescarga?.data?.attributes?.rf_kml?.data
        ) {
          const equipamento = new RegistroFechamentoDescargaMapModel(_equipamento, 'marron');

          setKmlRegistrosDescarga((prev) => [...prev, equipamento]);
        }
      });
    }
  }, [equipamentoList, tipoSelectedMap]);
  // Unidades Remotas
  useEffect(() => {
    if (unidadeRemotaList?.length !== 0 && tipoSelectedMap.includes('unidade_remota')) {
      unidadeRemotaList.forEach((unidade) => {
        !isNaN(parseFloat(unidade?.attributes?.lat)) &&
          !isNaN(parseFloat(unidade?.attributes?.long)) &&
          setRemotaPositions((prev) => [
            ...prev,
            {
              id: unidade?.id,
              attributes: unidade?.attributes,
              unidade_remota: true,
              tipo_equipamento: { color: 'text-yellow', icon: 'gear' },
              coordinates: [
                parseFloat(unidade?.attributes?.lat),
                parseFloat(unidade?.attributes?.long),
              ],
            },
          ]);
      });
    } else {
      setRemotaPositions([]);
    }
  }, [unidadeRemotaList, tipoSelectedMap]);

  const mapHomeView = useMemo(() => {
    return (
      (companySelected && (
        // <BRow addClassName={'row-map'}>
        <Map
          data={
            companySelected && {
              kmlSetores,
              kmlDistrito,
              kmlGeral: kmlRegistrosDescarga,
              kmlRegistros,
            }
          }
          reload={reload}
          markPositions={tipoSelectedMap !== 0 && equipamentoPosition}
          sistemaPositions={sistemaPositions}
          remotasPositions={RemotaPositions}
          CustomLayers={() => CustomLayer({ captacoesList, imovelList })}
          // id={'homeMap'}
        />
        // </BRow>
      )) || (
        // heigth complete container

        // <BRow addClassName={'row-map'}>
        <DefaultMap id={'homeMap'} />
        // </BRow>
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipamentoPosition, captacoesList, sistemaPositions, RemotaPositions, kmlRegistrosDescarga]);
  return mapHomeView;
};

export default MapHome;
