import P from 'prop-types';
import L from 'leaflet';
import { useEffect, useState } from 'react';
import { FeatureGroup, LayerGroup, LayersControl, Marker, Popup, useMap } from 'react-leaflet';
import { validarCoordenadas } from '../../../../../utils/coordinates-convert/validarCoordenadas';
import If from '../../../../../common/IF';
import IconCaptacao from '../../../../../layout/img/marker-icon.png';
import IconImovel from '../../../../../layout/img/marker-icon-blue.png';
import IconShadom from '../../../../../layout/img/marker-shadow.png';
import { Divider } from 'antd';
import { BtnModal, Modal, ModalBody, ModalFooter } from '../../../../../components/Modal';
import StreetView from '../../../../../components/Leaflat/NewMap/modules/StreetView';

export const CustomLayer = ({ captacoesList, imovelList }) => {
  const map = useMap();
  const [streetView, setStreetView] = useState(null);

  /* captacoes */
  useEffect(() => {
    if (captacoesList?.length !== 0) {
      map.on('overlayadd', async (e) => {
        const bounds =
          e.name === 'Captações' &&
          L.latLngBounds(
            captacoesList.map((captacao) => [
              validarCoordenadas(captacao?.attributes?.coor_lat, captacao?.attributes?.coor_long) &&
                captacao?.attributes?.coor_lat,
              captacao?.attributes?.coor_long,
            ]),
          );

        if (bounds && bounds.isValid()) {
          map.flyToBounds(bounds, { animation: true, padding: [10, 10], duration: 0.5 });
          // map.fitBounds(bounds, { animation: true, padding: [10, 10] });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);
  /* Imóveis */
  useEffect(() => {
    if (imovelList?.length !== 0) {
      map.on('overlayadd', (e) => {
        const bounds =
          e.name === 'Imoveis' &&
          L.latLngBounds(
            imovelList.map(
              (imovel) =>
                validarCoordenadas(imovel?.attributes?.lat, imovel?.attributes?.lng) && [
                  imovel?.attributes?.lat,
                  imovel?.attributes?.lng,
                ],
            ),
          );

        if (bounds && bounds.isValid()) {
          map.flyToBounds(bounds, { animation: true, padding: [10, 10], duration: 0.5 });
          // map.fitBounds(bounds, { animation: true, padding: [10, 10] });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);
  return (
    <>
      <If test={captacoesList?.length !== 0}>
        <LayersControl.Overlay name='Captações'>
          <LayerGroup pathOptions={{ color: 'blue' }}>
            {captacoesList.map((captacao) => {
              const icon = L.icon({
                iconUrl: IconCaptacao,
                shadowUrl: IconShadom,
                // html: `<i class="fas fa-hose-reel fa-3x  text-orange"></i>`,
                className: 'marker-icon',
                iconSize: [32, 32],
                shadowSize: [32, 32],
                shadowAnchor: [10, 35],
                iconAnchor: [16, 32],
                popupAnchor: [0, -32],
              });
              return (
                validarCoordenadas(
                  captacao?.attributes?.coor_lat,
                  captacao?.attributes?.coor_long,
                ) && (
                  <>
                    <Marker
                      key={captacao?.id}
                      position={[captacao?.attributes?.coor_lat, captacao?.attributes?.coor_long]}
                      icon={icon}
                    >
                      <Popup>
                        <p>{captacao?.attributes?.nome}</p>
                      </Popup>
                    </Marker>
                  </>
                )
              );
            })}
          </LayerGroup>
        </LayersControl.Overlay>
      </If>
      <If test={imovelList?.length !== 0}>
        <LayersControl.Overlay name='Imoveis'>
          <FeatureGroup pathOptions={{ color: 'blue' }}>
            {imovelList.map((imovel) => {
              const icon = L.icon({
                iconUrl: IconImovel,
                shadowUrl: IconShadom,
                className: 'marker-icon',
                iconSize: [20, 24],
                shadowAnchor: [10, 48],
                iconAnchor: [6, 32],
                popupAnchor: [0, -32],
              });
              const position = [imovel?.attributes?.lat, imovel?.attributes?.lng];
              return (
                validarCoordenadas(imovel?.attributes?.lat, imovel?.attributes?.lng) && (
                  <>
                    <Marker key={imovel?.id} position={position} icon={icon}>
                      <Popup>
                        <h4 className='text-center text-sm my-0 py-0'>
                          {imovel?.attributes?.nome}
                        </h4>
                        <Divider />
                        <p>
                          {imovel?.attributes?.address}, {imovel?.attributes?.address_number}
                          {imovel?.attributes?.address_complemento
                            ? ` - ${imovel?.attributes?.address_complemento}`
                            : ''}
                          <br />
                          {imovel?.attributes?.bairro}
                          {imovel?.attributes?.cidade ? ` - ${imovel?.attributes?.cidade}` : ''}
                          <br />
                          {imovel?.attributes?.cep}
                          {imovel?.attributes?.uf ? ` - ${imovel?.attributes?.uf}` : ''}
                        </p>
                        <Divider />
                        {/* geolocalização */}
                        <BtnModal
                          target={`streetView${imovel.id}`}
                          color={'primary'}
                          addClassName={'btn-block'}
                          text={'Ver no StreetView'}
                          onClick={() => setStreetView({ id: imovel.id, coordinates: position })}
                        />
                        <p className='d-flex justify-content-between gap-2'>
                          <span className='text-info'>Latitude: </span>
                          <span>{position[0]}</span>

                          <span className='text-info'>Longitude: </span>
                          <span>{position[1]}</span>
                        </p>{' '}
                      </Popup>
                    </Marker>
                  </>
                )
              );
            })}
          </FeatureGroup>
        </LayersControl.Overlay>
      </If>
      {streetView && (
        <Modal id={`streetView${streetView?.id}`} size={'xxl'} fade title={'Street View'}>
          <ModalBody style={{ height: '70vh' }}>
            <StreetView
              coordinates={{
                lat: streetView?.coordinates[0],
                lng: streetView?.coordinates[1],
              }}
            />
          </ModalBody>
          <ModalFooter btnCloseTitle={'Fechar'} id={streetView?.id} />
        </Modal>
      )}
    </>
  );
};

CustomLayer.propTypes = {
  captacoesList: P.array,
  imovelList: P.array,
};
