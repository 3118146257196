import React from 'react';
import P from 'prop-types';

/**
 *
 *@param {string} color - Valor padrão String - Adiciona padrões de cores ao componente Ex: primary, danger, warning
 * @param {string} addClassName - Valor padrão String - Adiciona novas classes ao componente  color: P.string,
 * @param {boolean} outline - Adiciona valor outline na classe pintando a borda superior
 * @example
 * - primary, secondary, success, warning,
 * @param {object} style - Valor padrão Objeto
 *  @example Ex: {boder:'1px', backgroundColor:'#333'}
 * @returns
 */
const Card = (props) => {
  const { outline, color, addClassName, style, onClick, className } = props;

  return (
    <div
      onClick={onClick && onClick}
      className={`card${addClassName ? ` ${addClassName}` : ''}${color ? ` card-${color}` : ''}${
        (outline && ' card-outline') || ''
      } ${className ? className : ''}`}
      style={(style && style) || {}}
    >
      {props.children}
    </div>
  );
};

export default Card;
Card.propTypes = {
  children: P.node.isRequired,
  addClassName: P.string,
  className: P.string,
  style: P.object,
  outline: P.bool,
  color: P.string,
  onClick: P.func,
};
