/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useState } from 'react';
import Navbar from '../components/Navbar';
import P from 'prop-types';
// import Sidebar from "../components/Sidebar/sidebar";
import Sidebar from '../components/Sidebar/sidebar';
import PageProvider from '../store/providers/PageProvider/PageProvider';
import Page from '../pages';
import LayoutProvider from '../store/providers/LayoutProvider/LayoutProvider';
import AuthProvider from '../store/providers/AuthProvider/AuthProvider';

import './style.css';
import { Footer } from '../components/Footer';
import CompanyProvider from '../store/providers/CompanyProvider';

import 'sweetalert2/src/sweetalert2.scss';
const Layout = (props) => {
  const [themeDark, setThemeDark] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const getAppTemplate = useCallback(() => {
    return (
      <AuthProvider>
        <CompanyProvider>
          {/** Navbar */}
          <Navbar
            setThemeDark={setThemeDark}
            themeDark={themeDark}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          {/** Main Sidebar Container */}
          <Sidebar themeDark={themeDark} collapsed={collapsed} />
          <LayoutProvider themeDark={themeDark}>
            {/**Content Wrapper. Contains page content */}
            <div className='content-wrapper'>
              {/** Main content */}

              <PageProvider>{(props.error === 404 && props.children) || <Page />}</PageProvider>
            </div>
            {/* <div className='mt-5 mb-5 divider'></div> */}
            <Footer themeDark={themeDark} />
          </LayoutProvider>
        </CompanyProvider>
      </AuthProvider>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeDark]);
  return getAppTemplate();
};

export default Layout;
Layout.propTypes = {
  children: P.node,
  error: P.number,
};
