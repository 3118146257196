import React, { useEffect, useState } from 'react';
import P from 'prop-types';
import { api, apiUrl } from '../../../config/modules/api';
import env from '../../../config/modules/env';
import { LayoutContext } from '../../../config/context/LayoutContext';

const LayoutProvider = ({ children, themeDark }) => {
  const [layoutState, setLayoutState] = useState([]);
  console.log('layoutProvider');
  useEffect(() => {
    const abortController = new AbortController();
    if (layoutState.length === 0) {
      const options = {
        signal: abortController.signal,
        method: 'GET',
        url: `${apiUrl}/api/layout/`,
        params: { populate: '*' },
        headers: {
          Authorization: `Bearer ${env('API_TOKEN')}`,
          'Content-Type': 'application/json',
        },
        // data: { data: { permissions_users: permissions_users } },
      };
      async function getLayoutState() {
        await api
          .request(options)
          .then((ress) => setLayoutState(ress.data.data))
          .catch((error) => {
            console.log(error.message);
          });
      }
      getLayoutState();
    }
    return () => abortController.abort();
  }, [layoutState]);

  //  console.log(layoutState);
  return (
    <LayoutContext.Provider value={{ layoutState, themeDark }} className='content'>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;

LayoutProvider.propTypes = {
  children: P.node.isRequired,
  themeDark: P.bool,
  setThemeDark: P.func,
};
