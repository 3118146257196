/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { GetFooter } from '../../pages/cadastros/utils/getFooter';
import HTMLReactParser from 'html-react-parser';
import { apiUrl } from '../../config/modules/api';
import { Link } from 'react-router-dom';
import P from 'prop-types';
import { Ctx } from '../../store/ctx/Ctx';

const Footer = (props) => {
  const { themeDark } = props;
  const dataFooter = GetFooter();
  const [footer, setFooter] = useState([]);
  const companyData = useContext(Ctx);
  const [logoSet, logoSetData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [iconSet, iconSetData] = useState([]);

  useEffect(() => {
    if (!themeDark && footer.length !== 0) {
      logoSetData(
        companyData[0]?.attributes.company_logo?.data?.attributes.url ||
          footer.attributes?.logofooter?.data?.attributes?.formats?.thumbnail.url ||
          '',
      );
      iconSetData(companyData[0]?.attributes.icon?.data?.attributes.url);
    } else {
      logoSetData(
        companyData[0]?.attributes.company_logo_dark?.data?.attributes.url ||
          footer.attributes?.logofooterDarkmode?.data?.attributes?.formats?.thumbnail.url ||
          '',
      );
      iconSetData(companyData[0]?.attributes.icon_dark?.data?.attributes.url);
    }
  }, [themeDark, footer.length]);

  useEffect(() => {
    if (footer.length === 0 && dataFooter) {
      setFooter(dataFooter);
    }
  }, [dataFooter]);

  if (!footer) {
    throw new Error('Ocorreu um Erro, por favor tente novamente mais tarde!');
  }

  return (
    <footer className='main-footer text-xs '>
      {footer.length !== 0 && (
        <div className='col-12 d-flex d-sm-block flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-between'>
          <div className='row'>
            <div className='col-sm-6 text-center text-sm-left'>
              <img
                src={logoSet && apiUrl + logoSet}
                alt='Logo Ecometria'
                className='imgLogo p-0 m-0'
                style={{ height: '20px' }}
              />{' '}
              {companyData[0]?.attributes?.company_description
                ? ''
                : ` ${HTMLReactParser(footer.attributes?.copyright)}`}
            </div>
            {(companyData[0]?.attributes?.company_description &&
              HTMLReactParser(companyData[0]?.attributes?.company_description)) || (
              <div className=' col-sm-6 text-center text-sm-right d-flex align-items-center justify-content-center justify-content-sm-end flex-nowrap flex-row '>
                <span>Contato: {footer.attributes?.footerContactTel || '(xx) 4305-9004'} </span>
                <span className='d-none d-xl-block'>
                  {' '}
                  Dúvidas e parcerias:{' '}
                  <Link to={`mailto:${footer.attributes?.footerEmail || ''}`}>
                    {footer.attributes?.footerEmail || 'email@contato.com.br'}
                  </Link>
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
Footer.propTypes = {
  themeDark: P.bool,
};
