/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unreachable */
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { api } from './api';
import jwt_decode from 'jwt-decode';
import { HasValue } from './HasValue';
import env from './env';
import { LoginSocket } from './loginSocket';

/**If login success Create Cookies Seave Session Auth */

export async function SetCookies(name, value, expires_in) {
  var token = value;

  // var decoded = token && jwt_decode(token);
  var dote = name === 'tk_uac' && token && token.split('.');
  // var data = new Date(decoded.exp);

  // var dias = Math.floor(data / (4 * 60 * 60 * 1000)); // Dividir pelo número de milissegundos em um dia (24 horas * 60 minutos * 60 segundos * 1000 milissegundos)

  Cookies.set(name, value, {
    expires: new Date(expires_in),
    path: '/',
    secure: true,
    sameSite: 'Lax',
    domain: !'https://ecoweb-dev.ecometria.com.br',
  });
  if (dote?.length !== 0) {
    localStorage.setItem('SessionAuth', dote[2]);
  } // name === 'tk_uac' &&
  //   Cookies.set('bs', dote[2], { expires: new Date(expires_in), path: '/', sameSite: 'Lax' });
  // name === 'tk_uac' && Cookies.set('exp', dote[1], { expires: new Date(expires_in) });
  sessionStorage.setItem('Session', env('SECRET'));
  LoginSocket();
}
export function GetUser(value) {
  var token = value;
  var decoded = token && jwt_decode(token);
  const [dataUser, setDataUser] = useState([]);
  useEffect(() => {
    async function GetDataUser() {
      await api
        .get(`/api/users/${decoded.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            populate: 'deep',
          },
        })
        .then((ress) => {
          setDataUser(ress.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
    GetDataUser();
  }, [value]);
  return dataUser;
}
export function Token() {
  var token = localStorage.getItem('tk_uac');
  // var decoded = token && jwt_decode(token);

  // var data = decoded && new Date(decoded.exp * 1000).getTime();

  // var now = new Date();
  // console.log({ data, now: now.getTime() });
  // if (data < now.getTime()) {
  //   // Cookies.remove('tk_uac');
  //   Cookies.remove('io');
  //   // Cookies.remove('bs');
  //   Cookies.set('ExpireSession', true);

  //   sessionStorage.clear();
  //   localStorage.clear();
  //   refreshPage('login');
  //   console.log(data, now);
  // } else {
  //   Cookies.remove('ExpireSession');
  // }
  return token;
}
/**Get Cookies */
export function GetCookies(name) {
  const cookies = Cookies.get(name);
  if (!cookies) {
    return false;
  }
  return cookies;
}

/**Get cookies user logdin */

// export function CookiesUser() {
//   const cookies = Cookies.get('tk_uac');

//   var token = cookies && cookies;
//   var dote = token && token.split('.');
//   var sessionAuth = localStorage.SessionAuth;

//   const [logdIn, setLogdIn] = useState();
//   const [dataUser, setDataUser] = useState([]);
//   useEffect(() => {
//     if (dataUser.length === 0) {
//       async function userResponse() {
//         await api
//           .get('/api/users/me', {
//             headers: {
//               Authorization: `Bearer ${cookies}`,
//             },
//             params: {
//               populate: 'deep',
//             },
//           })
//           .then((res) => {
//             console.log('API acessada por CookiesUser');
//             if (sessionAuth === dote[2]) {
//               setDataUser(res.data);
//               setLogdIn(true);
//             } else {
//               setLogdIn(false);

//               window.location.replace('/login');
//             }
//           })

//           .catch((error) => {
//             window.location.replace('/login');
//             console.log(error.message);
//           });
//       }
//       userResponse();
//     }
//   }, []);

//   if (cookies) {
//     return { cookies, logdIn, user: dataUser };
//   } else {
//     return logdIn;
//   }
// }

export function refreshPage(page) {
  if (page) {
    window.location.href = `/${page}`;
  }
}

export function Logout() {
  Cookies.remove('_ga_BPMS41FJD2');
  Cookies.remove('ph_phc_vqJhC8Gur3e5hySKKsNdlhHsHeDna2K1fxalCezayql_posthog');
  Cookies.remove('_ga');
  Cookies.remove('__utmz');
  Cookies.remove('_fbp');
  Cookies.remove('__utma');
  Cookies.remove('token_expires');
  Cookies.remove('expL');
  Cookies.remove('AUTH-iqatech');

  // Cookies.remove('token_expires');
  Cookies.remove('ExpireSession');
  sessionStorage.clear();
  localStorage.removeItem('tk_uac');
  localStorage.removeItem('token_expires');
  localStorage.removeItem('SessionAuth');
  localStorage.removeItem('userData');
  localStorage.removeItem('auth0Token');
  localStorage.removeItem('user_company');
  localStorage.removeItem('user_profile');
  refreshPage('login');
}
export const isAuthenticated = () => {
  const token = Token();
  if (token) {
    return true;
  } else {
    return false;
  }
};

export function Validation(token, page) {
  if (!token.userData) {
    // console.log(token?.userData?.user_profile?.permissions_users);
  } else {
    if (
      token?.userData?.user_profile?.title !== 'Administrador' &&
      (!token.userData?.user_profile ||
        !HasValue(token?.userData?.user_profile.permissions_users, 'ref', page))
    ) {
      return false;
    } else {
      return true;
    }
  }
  if (!token.userData) {
    return false;
  } else {
    if (
      !token.userData.user_profile ||
      !HasValue(token.userData.user_profile.permissions_users, 'ref', page)
    ) {
      return false;
    } else {
      return true;
    }
  }
}
export const apiOptions = () => {
  const token = Token();
  const options = {
    headers: {
      Authorization: `Bearer ${token} `,
    },
    params: {},
  };
  if (typeof options.params === 'object' && options.params !== null) {
    options.params.populate = '*';
  } else {
    options.params = {
      populate: '*',
    };
  }
  return { ...options };
};

// Função para verificar a expiração do token
