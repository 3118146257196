import React, { useState } from 'react';
import P from 'prop-types';

import { Validation } from './util/Validation';
import { Card, CardBody } from '../../components/Bases/Card';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom/dist';

import UseAudit from '../../myHooks/useAudit';
import jwtDecode from 'jwt-decode';
import { Skeleton } from 'antd';
import useResendConfirmationEmail from '../../myHooks/resendConfirmation/useResendConfirmationEmail';

const FormLogin = ({ state, modalOpen, setResendToken }) => {
  const [stateLogin, setStateLogin] = useState([]);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [resendconfirmation, setRessendconfirmation] = useState(false);
  const [buttonConfirmation, setButtonConfirmation] = useState(false);
  const Resent = useResendConfirmationEmail(cancelResent);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  function cancelResent() {
    setRessendconfirmation(false);
  }
  // ...

  // const iqatechLogin = async () => {
  //   const url = 'http://localhost:8000/api/v1/auth/login';
  //   let options = {
  //     email: 'fabiopasilva1@gmail.com',
  //     password: 'K2108f84||',
  //   };

  //   // const response = await axios.post(url, request)
  //   const response = await import('../../config/modules/api').then(({ api }) =>
  //     api
  //       .post(url, options, {
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       })
  //       .catch((e) => console.error(e)),
  //   );

  //   return response;
  // };

  /* Login padrao */
  const onSubmit = async (data) => {
    setLoadingLogin(true);
    // await iqatechLogin(data);
    if (data) {
      try {
        const response = await import('../../config/modules/api').then(({ api }) =>
          api.post('api/auth/local', {
            identifier: data.email,
            password: data.password,
          }),
        );

        const { jwt, user } = response.data;
        if (response) {
          UseAudit({
            action: 'Login',
            data: response.data?.user,
            username: response?.data?.user?.username,
            content_id: user.id,
            jwt,
          });

          await import('./util/PermissionsUser').then(({ PermissionsUser }) =>
            PermissionsUser(user.id, jwt),
          );
        }
        // Decodificar o token JWT
        const decodedToken = jwtDecode(jwt);
        const { exp, iat } = decodedToken;

        setStateLogin({ jwt, user, expires_in: exp, expireLogin: exp - iat });

        if (jwt) {
          toast.success('Login efetuado com scuesso!');
          setResendToken && setResendToken(true);
        }

        // Restante da lógica de autenticação...
      } catch (error) {
        console.log({ error });
        if (error?.response?.status >= 400) {
          error.response?.data?.error?.message === 'Your account email is not confirmed' &&
            setButtonConfirmation(true);
          const msg =
            error.response?.data?.error?.message === 'Invalid identifier or password'
              ? 'Usuário ou senha inválidos!'
              : error.response?.data?.error?.message === 'Your account email is not confirmed'
              ? 'Seu e-mail ainda não foi confirmado!, Verifique o seu email, não esqueça da caixa de Span! '
              : error.response?.data?.error?.message ===
                  'Your account has been blocked by an administrator' &&
                'Sua conta está bloqueada, por favor entre em contato com o seu Administrador!';

          toast.error(msg, { autoClose: 7000 });
        }
        setLoadingLogin(false);
        console.error(error);
      }
    }
  };

  if (stateLogin) {
    Validation(stateLogin, setLoadingLogin, state, modalOpen, setResendToken);
  }

  return !resendconfirmation ? (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardBody>
            {!loadingLogin ? (
              <div className='d-flex justify-content-center align-items-center flex-column'>
                <div className='input-group mb-3 col-12'>
                  <div className='input-group-append '>
                    <div className='input-group-text '>
                      <span className='fas fa-envelope'></span>
                    </div>
                  </div>
                  <input
                    name='email'
                    {...register('email', { required: true })}
                    type='email'
                    className='form-control '
                    placeholder='Email'
                  />

                  <p className='text-danger col-md-12'>
                    {errors?.email && (
                      <>
                        <i className='fas fa-triangle-exclamation'></i>Informe um email válido!
                      </>
                    )}
                  </p>
                </div>
                <div className='input-group mb-3 col-12'>
                  <div className='input-group-append '>
                    <div className='input-group-text'>
                      <span className='fas fa-lock'></span>
                    </div>
                  </div>
                  <input
                    name='password'
                    {...register('password', { required: true })}
                    type='password'
                    className='form-control '
                    placeholder='Password'
                  />

                  <p className='text-danger col-md-12'>
                    {errors?.password && (
                      <>
                        {' '}
                        <i className='fas fa-triangle-exclamation'></i> Informe uma senha!
                      </>
                    )}
                  </p>
                </div>
                <div className='input-group  col-md-10 ml-0 p-0 p-md-2 ml-md-5'>
                  {/* /.col */}
                  <div className='col-12'>
                    <button type='submit' className='btn btn-info btn-block btn-flat mb-2'>
                      Entrar
                    </button>
                    {buttonConfirmation ? (
                      <button
                        type='button'
                        className='btn btn-primary btn-flat btn-block mb-2 '
                        onClick={() => setRessendconfirmation(!resendconfirmation)}
                      >
                        Renviar confirmação <i className='fas fa-paper-plane'></i>
                      </button>
                    ) : (
                      ''
                    )}
                    <Link
                      to='/forgot-password'
                      className='btn btn-muted text-light d-md-none btn-flat'
                    >
                      Esqueci a senha! <i className='fas fa-unlock-alt '></i>
                    </Link>{' '}
                    {/*  */}
                    <Link
                      to='/forgot-password'
                      className='btn btn-muted text-default-info d-none d-md-block btn-flat'
                    >
                      Esqueci a senha! <i className='fas fa-unlock-alt '></i>
                    </Link>{' '}
                    {/* <Link to={'/forgot-password'}>Esqueci a senha</Link> */}
                  </div>

                  {/* /.col */}
                </div>
              </div>
            ) : (
              <>
                <div
                  style={{
                    position: 'absolute',
                    margin: 'auto',
                    width: '90%',
                    backgroundColor: '#f8f8f878',
                  }}
                >
                  <h3 style={{ color: '#0c0c0c', textAlign: 'center' }} className='px-1 py-2'>
                    Efetuando Login!
                  </h3>
                </div>
                <Skeleton active={loadingLogin} loading={loadingLogin} title={'Efetuando login'} />
              </>
            )}
          </CardBody>
        </Card>
      </form>
    </>
  ) : (
    <>
      <Card>
        <CardBody>{Resent}</CardBody>
      </Card>
    </>
  );
};

export default React.memo(FormLogin);
FormLogin.propTypes = {
  handleSubmit: P.func,
  handleChange: P.func,
  msgText: P.node,
  company: P.object,
  setResendToken: P.func,
  state: P.object,
  modalOpen: P.func,
};
