import { api } from '../../../config/modules/api';

export async function PermissionsUser(id, token) {
  const response = await api.get('/api/user-profiles', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      populate: '*',
      'filters[users][id][$eq]': id,
    },
  });
  try {
    if (response.data) {
      return response;
    }
  } catch (e) {
    throw new Error('Ocorreu um erro ao tentar localizar as permissões do Usuário');
  }
}
