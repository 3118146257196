import $ from 'jquery';
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle';

export function CloseModal(id) {
  if (id) {
    console.log({ id });
    $(`#${id}`).modal('hide');
  } else {
    $('.modal').modal('hide');
  }
}
