import P from 'prop-types';
import React from 'react'

import ContentHeader from '../../../../components/Header/ContentHeader';
import { BContainer, BRow } from '../../../../components/AllComponents';

const HeaderContent = ({ children, config }) => {

  return (
    <>
      {(config?.title || config?.breadcrumbs?.length !== 0)
        && (<ContentHeader title={config?.title} breadcrumb={config?.breadcrumbs} />)}
      <BContainer fluid>
        <BRow>
          {children}
        </BRow>
      </BContainer>
    </>
  )
}

HeaderContent.propTypes = {
  children: P.node,
  config: P.shape({
    title: P.string,
    breadcrumbs: P.array,
  })
}

export default HeaderContent
