import React, { useState, useEffect } from 'react';
import socketIOClient from 'socket.io-client';
import env from '../../config/modules/env';
import { api } from '../../config/modules/api';

const TestSocket = () => {
  const [mqttTopic, setMqttTopic] = useState('');
  const [mqttMessage, setMqttMessage] = useState([]);
  const [dadosHistorico, setDadoshistorico] = useState([]);
  const secret = env('SERVER_SECRET_KEY');
  console.log({ dadosHistorico, mqttMessage });
  var sec = 1000;
  var min = sec * 60;
  var hor = min * 60;
  var day = hor * 24;
  const mes = 1000 * 60 * 49 * 24 * 30;
  console.log(day, mes);

  const data = dadosHistorico[0] && new Date(dadosHistorico[0][0].ts).toLocaleString('pt-BR');
  console.log(data);
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState(secret); // Substitua por lógica real de autenticação
  const apiHost = 'http://localhost:3884';
  const socketHost = 'ws://localhost:3884';
  const socket = socketIOClient(socketHost, {
    auth: { token },
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
  });
  useEffect(() => {
    if (dadosHistorico.length === 0) {
      api
        .get(`${apiHost}/api/equipamentos`, {
          headers: {
            authorization: `Bearer ${secret}`,
          },
          params: {
            table: 'pointValues',
            filter: "dataPointId = (SELECT id FROM dataPoints where xid = '8f4714_4')",
            order: 'ts:desc',
          },
        })
        .then((r) => setDadoshistorico((dadosHistorico) => [...dadosHistorico, r.data]))
        .catch((e) => console.log(e));
    }
  });
  useEffect(() => {
    socket.on('connect', () => {
      console.log('Conectado ao servidor Socket.IO');
    });

    socket.on('reconnect', (attemptNumber) => {
      console.log(`Reconectado após ${attemptNumber} tentativas`);
    });

    socket.on('reconnect_attempt', (attemptNumber) => {
      console.log(`Tentativa de reconexão número ${attemptNumber}`);
      // Você pode adicionar lógica aqui, se necessário
    });

    socket.on('mqttMessage', (data) => {
      setMqttTopic(data.topic);
      setMqttMessage((prev) => [...prev, JSON.parse(data.message)]);
    });
    socket.on('connect_error', (err) => {
      console.error('Erro de conexão Socket.IO:', err);
    });
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  const subscribeToMqtt = () => {
    socket.emit('subscribeToMqtt', mqttTopic);
  };

  return (
    <div className='container'>
      <div className='row'>
        <div className='col col-12'>
          <input
            type='text'
            placeholder='Digite o tópico MQTT'
            value={mqttTopic}
            onChange={(e) => setMqttTopic(e.target.value)}
          />
          <button onClick={subscribeToMqtt}>Inscrever-se no MQTT</button>
          <p>Último tópico MQTT: {mqttTopic}</p>
          <p className='text-wrap'>
            Última mensagem MQTT: {mqttMessage && mqttMessage.map((msg) => JSON.stringify(msg))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TestSocket;
