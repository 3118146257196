import React from 'react';
import P from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
/**
 *
 * @param {String} btnSubmitTitle Adiciona um texto para o botão
 * @param {String} styleBtnSubmit Adiciona um estilo padão do tema
 * @example className={'btn bnt-primary'}
 * @param {String} btnCloseTitle Adiciona um texto para o botão
 * @param {Object} customButton Adiciona um botão custom para o botão
 * @param {Boolean} customButton.show Adiciona um botão custom para o botão
 * @param {String} customButton.color Adiciona um estilo padão do tema
 * @param {String} customButton.icon Adiciona um ícone para o botão
 * @param {String} customButton.text Adiciona um texto para o botão
 * @param {Function} customButton.onClick Adiciona um evento para o botão
 * @param {String} btnJustify Adiciona um estilo padão do tema start, end, between, around
 * @returns
 */

const ModalFooter = (props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
  const {
    btnSubmitTitle,
    styleBtnSubmit,
    btnCloseTitle,
    loading,
    cancelFunction,
    customButton,
    btnJustify,
    closeModal,
  } = props;

  return (
    <div className={`modal-footer align-items-right justify-content-${btnJustify}`}>
      {btnSubmitTitle && (
        <button
          disabled={loading || false}
          type='submit'
          className={`btn btn-flat ${styleBtnSubmit ? `btn-${styleBtnSubmit}` : 'btn-info'} `}
        >
          {loading && <Spin indicator={antIcon} />} {btnSubmitTitle ? btnSubmitTitle : 'Adicionar'}
        </button>
      )}
      {customButton?.show && (
        <button
          type='button'
          className={`btn btn-${customButton?.color ? customButton.color : 'info '} btn-flat`}
          onClick={customButton?.onClick}
          data-dismiss={customButton?.dismiss}
        >
          {customButton?.icon ? <i className={`fa fa-${customButton.icon}`}></i> : ''}{' '}
          {customButton?.text ? customButton.text : ''}
        </button>
      )}
      {btnCloseTitle && (
        <button
          disabled={loading || false}
          type='button'
          className={`btn btn-flat btn-outline-light`}
          data-dismiss='modal'
          onClick={() => {
            cancelFunction && cancelFunction();
            closeModal && closeModal();
          }}
        >
          {btnCloseTitle}
        </button>
      )}
      {props.children}
    </div>
  );
};

export default ModalFooter;
ModalFooter.propTypes = {
  btnSubmitTitle: P.string,
  children: P.node,
  styleBtnSubmit: P.string,
  btnCloseTitle: P.string,
  loading: P.bool,
  cancelFunction: P.func,
  customButton: P.object,
  btnJustify: P.string,
  closeModal: P.func,
};
