import P from 'prop-types';
import { LayersControl, TileLayer } from 'react-leaflet';
import { TileProviders } from '../../lib/TileProviders';

export function LayersControlCustom({ CustomLayers }) {
  return (
    <LayersControl collapsed={true}>
      <LayersControl.BaseLayer checked name='SATÉLITE'>
        <TileLayer
          url={TileProviders.GoogleMap.variants.GoogleSatellite?.url}
          attribution={TileProviders.GoogleMap?.variants.GoogleSatellite?.options?.attribution}
          maxZoom={TileProviders.GoogleMap.variants.GoogleSatellite?.options?.maxZoom}
          detectRetina={true}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name='GOOGLEMAP'>
        <TileLayer
          url={TileProviders.GoogleMap.variants.GoogleMap?.url}
          attribution={TileProviders.GoogleMap?.variants.GoogleMap?.options?.attribution}
          maxZoom={TileProviders.GoogleMap.variants.GoogleMap?.options?.maxZoom}
          detectRetina={true}
        />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name='OpenStreetMap'>
        <TileLayer
          url={TileProviders.OpenStreetMap?.url}
          attribution={TileProviders.OpenStreetMap?.options?.attribution}
          maxZoom={TileProviders.OpenStreetMap?.options?.maxZoom}
          minZoom={2}
          detectRetina={true}
        />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name='HIBRIDO'>
        <TileLayer
          url={TileProviders.GoogleMap.variants.GoogleHybrid?.url}
          attribution={TileProviders.GoogleMap?.variants.GoogleHybrid?.options?.attribution}
          maxZoom={22}
          minZoom={2}
          detectRetina={true}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name='RELEVO'>
        <TileLayer
          url={TileProviders.GoogleMap.variants.GoogleTerrain?.url}
          attribution={TileProviders.GoogleMap?.variants.GoogleTerrain?.options?.attribution}
          maxZoom={22}
          minZoom={2}
          detectRetina={true}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name='RUAS'>
        <TileLayer
          url={TileProviders.GoogleMap.variants.GoogleTransit?.url}
          attribution={TileProviders.GoogleMap?.variants.GoogleTransit?.options?.attribution}
          maxZoom={TileProviders.GoogleMap?.variants.GoogleTransit?.options?.maxZoom}
          minZoom={2}
          detectRetina={true}
        />
      </LayersControl.BaseLayer>
      {CustomLayers && <CustomLayers />}
    </LayersControl>
  );
}

LayersControlCustom.propTypes = {
  CustomLayers: P.node,
};
