export const iconColors = (tipoSelectedMap) => [
  {
    title: 'Medidor de Vazão',
    color: tipoSelectedMap.includes('detalhe_macromedidor') ? 'red' : 'muted',
    type: 'detalhe_macromedidor',
    icon: 'location-dot',
  },
  {
    title: 'Calha Parshall',
    color: tipoSelectedMap.includes('detalhe_calhaparshall') ? 'yellow' : 'muted',
    type: 'detalhe_calhaparshall',
    icon: 'location-dot',
  },
  {
    title: 'Calha Palmer',
    color: tipoSelectedMap.includes('detalhe_palmer') ? 'purple' : 'muted',
    type: 'detalhe_palmer',
    icon: 'location-dot',
  },
  {
    title: 'Reservatório',
    color: tipoSelectedMap.includes('reservatorio') ? 'blue' : 'muted',
    type: 'reservatorio',
    icon: 'location-dot',
  },
  {
    title: 'Transmissor de Pressão',
    color: tipoSelectedMap.includes('detalhe_transmissor_pressao') ? 'orange' : 'muted',
    type: 'detalhe_transmissor_pressao',
    icon: 'location-dot',
  },
  {
    title: 'VRP',
    color: tipoSelectedMap.includes('detalhe_vrp') ? 'green' : 'muted',
    type: 'detalhe_vrp',
    icon: 'location-dot',
  },
  {
    title: 'Hidrante',
    color: tipoSelectedMap.includes('detalhe_hidrante') ? 'danger' : 'muted',
    type: 'detalhe_hidrante',
  },
  {
    title: 'Registro de Fechamento',
    color: tipoSelectedMap.includes('detalhe_registrofechamento') ? 'primary-default' : 'muted',
    type: 'detalhe_registrofechamento',
    icon: 'location-dot',
  },
  {
    title: 'Registro de Descarga',
    color: tipoSelectedMap.includes('detalhe_registrodescarga') ? 'marron' : 'muted',
    type: 'detalhe_registrodescarga',
    icon: 'location-dot',
  },
  {
    title: 'Unidades Remotas',
    color: tipoSelectedMap.includes('unidade_remota') ? 'yellow' : 'muted',
    type: 'unidade_remota',
    icon: 'gear',
  },
];
