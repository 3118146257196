import { api } from '../config/modules/api';
import axios from 'axios';

/**
 *
 * @param {String} page_ref -- Salva a página visitada
 * @param {String} action -- Salva qual ação foi realizada na página
 * @param {Object} data -- Salva um objeto da resposta da ação realizada
 * @param {Number} content_id -- Salva o id do conteudo alterado
 */

const UseAudit = async ({ action, data, content_id, jwt }) => {
  const location = window.location.href;
  // Get user data from local storage
  const token = jwt ?? localStorage.getItem('tk_uac');
  const userData = JSON.parse(localStorage.getItem('userData'));
  // Get user company from local storage
  const userCompany = JSON.parse(localStorage.getItem('user_company'));
  // Set IP address
  // Get IP address from API

  const ipManage = async () => {
    await axios
      .get('https://api.ipify.org?format=json')
      .then((ip) => {
        if (ip.data) {
          postAudit(ip);
        }
      })
      .catch((e) => console.error(e));
  };

  /**
   * Send audit data to API
   * @param {Object} ip - IP address of the user
   */
  async function postAudit(ip) {
    const dataConfig = {
      data: {
        // Get username from local storage or from the data object
        username: userData?.username || data?.name,
        // Get email from local storage or from the data object
        email: userData?.email || data?.email,
        // Get IP address from API
        ip: ip.data.ip,
        // Get the page URL from the browser
        page_ref: location,
        // Get the action performed on the page
        action,
        // Get the data object from the component
        data: data,
        // Get the content ID from the component
        content_id: content_id && content_id.toString(),
        // Get the user ID from local storage or from the data object
        users_permissions_user: (userData && +userData?.id) || data?.id,
        // Get the company ID from local storage
        company: userCompany?.id,
      },
    };

    // Send the data to the API
    await api
      .post('/api/audits', dataConfig, {
        headers: token && {
          Authorization: `Bearer ${token}`,
        },
        // Populate the response with all related data
        params: {
          populate: '*',
        },
      })
      .then((r) => {
        console.log('Audit Registrado!');
      })
      .catch((e) => console.error(e));
  }
  // Get IP address on first render

  // Save audit data to local storage
  ipManage();
  // Save audit data to API
};

export default UseAudit;
