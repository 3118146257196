/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from 'react';
import P from 'prop-types';
import { AuthContext } from '../../../config/context/AuthContext';
import { Logout, isAuthenticated } from '../../../config/modules/helpers';
import { loadLogin } from '../../../config/actions/login';
import ReducerLogin from '../../../config/reducers/reducerLogin';
import { userData } from '../../../config/reducers/data';
import { LOGIN_SUCCESS } from '../../../config/reducers/ContentTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginSocket } from '../../../config/modules/loginSocket';
import jwt_decode from 'jwt-decode';
import { Button, Modal } from 'antd';
import { params } from './constants/authParams';
import FormLogin from '../../../pages/login/FormLogin';
import { Auth } from './auth/Auth';

const AuthProvider = ({ children }) => {
  console.log('AuthProvider');
  const location = useLocation();
  const [loginState, loginDispatch] = useReducer(ReducerLogin, userData);
  const navigate = useNavigate();
  // console.log(loginState.userData);
  const adm =
    loginState?.userData?.user_profile?.title === 'Administrador' ||
    loginState?.userData?.user_profile?.adm;
  const ecoRegex = RegExp(/ECOMETRIA/);
  const eco_user = ecoRegex.test(loginState?.userData?.user_profile?.title);

  const [isAuthenticate, setIsAuthenticate] = useState(isAuthenticated);
  const token = Auth();

  const tokenApi = Auth();
  var decoded = token && jwt_decode(token);
  // var dote = token && token.split('.');

  // Data de expiração do token
  var data = decoded && new Date(decoded?.exp * 1000);
  // console.log({
  //   TOKEN_ECP:
  //     data.getDate() +
  //     '/' +
  //     (data.getMonth() + 1) +
  //     '/' +
  //     data.getFullYear() +
  //     ' ' +
  //     data.getHours() +
  //     ':' +
  //     data.getMinutes() +
  //     ':' +
  //     data.getSeconds(),
  // });
  // eslint-disable-next-line no-unused-vars

  const [resendToken, setResendToken] = useState(false);
  const [loginExpired, setLoginExpired] = useState(false);

  useEffect(() => {
    if (token || resendToken) {
      const timeExpire = data.getTime() - 60000;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        params,
      };
      loadLogin(loginDispatch, '/api/users/me', options)
        .then((loginDispatch) => {
          loginDispatch({ type: LOGIN_SUCCESS, payload: userData });
          setIsAuthenticate(true);

          startTokenRefresh(timeExpire - Date.now());
          setLoginExpired(false);
        })
        .catch((e) => {
          setIsAuthenticate(false);
        });
    } else {
      Logout();
    }
    setResendToken(false);
  }, [token, resendToken]);

  useEffect(() => {
    if (!tokenApi) {
      LoginSocket();
    }
  }, []);

  const startTokenRefresh = (expiresIn) => {
    // setCronometro(refreshTime);
    // setTempoInicial(refreshTime); console.log({ expirationTime });
    setTimeout(async () => {
      LoginSocket();

      setLoginExpired(true);
    }, expiresIn);
  };

  if (!isAuthenticate) {
    return navigate('/login', { state: { key: location.pathname } });
  }

  return (
    <AuthContext.Provider value={{ ...loginState, adm, token, eco_user }} className='content'>
      {children}
      {loginExpired && (
        <Modal
          open={loginExpired}
          title='Sessão Expirou'
          style={{
            top: 20,
          }}
          className='expireSession'
          centered
          footer={[
            <Button
              key='back'
              onClick={() => {
                navigate('/login', { state: { key: location.pathname } });
                Logout();
              }}
            >
              Sair
            </Button>,
          ]}
        >
          <h4 className='text-center'>Efetue o login novamente</h4>
          <FormLogin
            state={{ key: location.pathname }}
            modalOpen={setLoginExpired}
            setResendToken={setResendToken}
          />
        </Modal>
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

AuthProvider.propTypes = {
  children: P.node.isRequired,
};
