import P from 'prop-types';
import { styleFunction } from '../styleFunction';
import omnivore from 'leaflet-omnivore';
import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { Modal, ModalBody, ModalFooter } from '../../../Modal';
import StreetView from './StreetView';

export const KmlLayer = ({ kmlUrl }) => {
  const map = useMap();
  const [streetView, setStreetView] = useState({
    id: '',
    coordinates: [],
  });
  const iconPerson = new L.divIcon({
    html: `<i class="fas fa-location-dot p-0 text-danger opacity-1 marker-icon " style="font-size:32px; text-shadow: 0px 8px 6px rgba(0,0,0,0.29); " ></i>`,
    className: 'marker-icon',
    // iconUrl: require('../../../layout/img/marker-icon.png'),
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });

  useEffect(() => {
    // Carrega o arquivo KML e adiciona ao mapa
    const kmlLayer = omnivore.kml(kmlUrl?.url).addTo(map);

    // Opcional: ajusta a visão do mapa para a camada KML
    kmlLayer.on('ready', (e) => {
      // map.fitBounds(kmlLayer.getBounds(), { maxZoom: 22, minZoom: 2 });
      map.flyToBounds(kmlLayer.getBounds(), {
        animation: true,
        padding: [10, 10],
        duration: 0.5,
        maxZoom: 22,
        minZoom: 2,
      });

      kmlLayer.eachLayer((layer) => {
        layer?.setStyle && layer?.setStyle(styleFunction(layer?.feature, layer?.options));

        // layer.bindPopup(function (layer) {
        //   return kmlUrl?.name ?? layer?.feature?.properties?.name ?? '';
        // });

        if (layer?.feature?.geometry?.type === 'Point') {
          var z = document.createElement('p'); // is a node
          z.innerHTML = `<p>${
            kmlUrl?.name ?? layer?.feature?.properties?.name ?? ''
          }</p>  Lat: <span class='text-info text-xs'>${
            layer?.getLatLng()?.lat
          }</span> Lng: <span class='text-info text-xs'>${layer?.getLatLng()?.lng}</span>

        </br ><button type="button" class="btn btn-flat btn-block btn-info" data-toggle="modal" data-target="#modal" >Ver no StreetView</button>`;
        }
        layer?.feature?.geometry?.type === 'Point'
          ? setStreetView({
              ...streetView,
              id: layer?.feature?.id,
              coordinates: [layer?.getLatLng()?.lat, layer?.getLatLng()?.lng],
            })
          : layer.bindPopup(function (layer) {
              return kmlUrl?.name ?? layer?.feature?.properties?.name ?? '';
            });

        if (layer instanceof L.Marker) {
          layer.bindPopup(z);
          layer.setIcon(iconPerson);
        }
      });
    });

    // Limpa a camada quando o componente é desmontado ou o URL muda
    return () => {
      console.log('Desmontado, antkml');
      kmlLayer ? kmlLayer.remove() : console.log('Desmontado, null');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kmlUrl, map]);

  return (
    <>
      <Modal id='modal' fade size={'xl'}>
        <ModalBody style={{ height: '70vh' }}>
          <StreetView
            coordinates={{
              lat: streetView?.coordinates[0],
              lng: streetView?.coordinates[1],
            }}
          />
        </ModalBody>
        <ModalFooter btnCloseTitle={'Fechar'} id={streetView?.id} />
      </Modal>
      ;
    </>
  );
  // Não precisa renderizar nada
};
KmlLayer.propTypes = {
  kmlUrl: P.string.isRequired,
};
