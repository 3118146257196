import React from 'react';
import P from 'prop-types';
import { ExportExcel } from '../../Tables/Excelexport';

const CardTools = (props) => {
  const { minus, expand, remove, exportExcel, excelData, exportCsv } = props;

  const tolls = {
    minus: (
      <button className='btn btn-toll' type='button' data-card-widget='collapse' title='Minimizar'>
        <i className='fas fa-minus'></i>
      </button>
    ),
    expand: (
      <button
        className='btn btn-toll'
        type='button'
        data-card-widget='maximize'
        title='Tela inteira'
      >
        <i className='fas fa-expand'></i>
      </button>
    ),
    remove: (
      <button className='btn btn-toll' type='button' data-card-widget='remove' title='Remover Card'>
        <i className='fas fa-times'></i>
      </button>
    ),
    exportExcel: (
      <button
        type='button'
        title='Exportar em Excel'
        className='btn btn-toll text-success'
        onClick={() =>
          ExportExcel({
            excelData: excelData,
            fileName: 'ListaEquipamentos',
            format: 'xlsx',
          })
        }
      >
        <i className='fas fa-file-spreadsheet '></i>
      </button>
    ),
    exportCsv: (
      <button
        type='button'
        title='Exportar em Excel'
        className='btn btn-toll text-success'
        onClick={() =>
          ExportExcel({
            excelData: excelData,
            fileName: 'ListaEquipamentos',
            format: 'csv',
          })
        }
      >
        <i className='fa fa-file-csv p-sm-1'></i>
      </button>
    ),
  };
  const tollsMap =
    (minus && tolls.minus) ||
    (expand && tolls.expand) ||
    (remove && tolls.remove) ||
    (exportExcel && tolls.exportExcel) ||
    (exportCsv && tolls.exportCsv);

  return <div className='card-tools'>{tollsMap}</div>;
};

CardTools.propTypes = {
  minus: P.bool,
  expand: P.bool,
  remove: P.bool,
  exportExcel: P.bool,
  exportCsv: P.bool,
  excelData: P.array,
};

export default CardTools;
