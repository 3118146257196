import React from 'react';
import P from 'prop-types';

const BContainer = React.forwardRef(function BContainer(props, ref) {
  const { addClassName, fluid, className } = props;
  return (
    <div
      ref={ref}
      className={`container${fluid ? '-fluid' : ''} ${addClassName ? addClassName : ''} ${
        className ? className : ''
      }`}
    >
      {props.children}
    </div>
  );
});

export default BContainer;
BContainer.propTypes = {
  children: P.node,
  addClassName: P.string,
  className: P.string,
  fluid: P.bool,
};
