export function validarCoordenadas(latitude, longitude) {
  if (
    latitude !== undefined &&
    longitude !== undefined &&
    latitude !== null &&
    longitude !== null &&
    latitude >= -90 &&
    latitude <= 90 &&
    longitude >= -180 &&
    longitude <= 180
  ) {
    return true;
  } else {
    return false;
  }
}
