import React, { useEffect, useState } from 'react';
import P from 'prop-types';
import { Select } from 'antd';

/**
 * FilterSelect component for handling filter selection.
 *

 * @param {Function} onChange - Callback function triggered on selection change.
 * @param {Object} config - Configuration object for customizing the select input.
 * @param {Array} options - Configuration object for tag <option>the select input.
 * @example - options:[
 *  {
 *    value: value 1,
 *    label: 'Text label 1'
 *  },
 *  {
 *    value: value 2,
 *    label: 'Text label 2'
 *  },
 *  {etc...}
 * ]
 *
 * @param {String} defaultValue - Value for default value for cusomizing the select input.
 * @param {Boolean} loading - Flag to indicate if the select input is in loading state.
 * @param {String} errorMessage - Error message to display when an error occurs.
 * @param {Boolean} activeOnReload - Flag to indicate if the select input should be active on reload.
 * @param {Object} config - Configuration object for customizing the select input.
 * @param {Bool} config.multiple - Flag to indicate if the select input should support multiple selections.
 * @param {String} config.className - CSS class name for customizing the select input.
 * @param {String} config.placeholder - Placeholder text for the select input.
 * @param {String} config.disabled - Flag to indicate if the select input should be disabled.
 * @param {Bool} config.label - Flag to indicate if the select input should display a label.
 * @param {String} config.labelText - Text for the label of the select input.
 * @param {Function} onReload - Callback function to handle reload events.
 * @returns {JSX.Element} - Returns a select input JSX element for filtering.
 * @constructor
 * @component FilterSelect
 * @category Forms
 * @subcategory FilterSelect
 * @author Fabio Pereira
 * @version 1.0.0
 *
 *
 * @example
 * // Assume a parent component has a state variable called 'selectedFilter' and a function 'handleFilterChange'
 * // to handle changes in the filter selection.
 *
 * // In the parent component:
 * // const [selectedFilter, setSelectedFilter] = useState('');
 *
 * // const handleFilterChange = (value) => {
 * //   setSelectedFilter(value);
 * //   // Perform additional filter-related logic here
 * //   console.log('Selected Filter:', value);
 * // };
 *
 * // In the render method of the parent component:
 *   <FilterSelect
              onChange={funcOnChange}
              config={{
                className: 'custom-filter-select',
                placeholder: 'Filtrar por Empresa',
              }}
              options={data?.map((item) => {
                console.log({ item });
                return {
                  value: item.id,
                  label: item?.attributes?.title,
                };
              })}
            />
 */
const FilterSelect = ({
  onChange,
  config,
  options,
  key,
  loading,
  errorMessage,
  activeOnReload,
  onReload,
  emptyMessage,
}) => {
  /**
   * Handles the change event of the select input.
   * @param {Object} e - The event object.
   */
  const [defaultValue, setDefaultValue] = useState(config?.defaultValue);
  const handleChange = (e) => {
    onChange(e.target.value);
    setDefaultValue(e.target.value);
  };

  // set select input props

  useEffect(() => {
    if (config?.defaultValue) {
      setDefaultValue(config?.defaultValue);
    }
  }, [config]);
  const inputProps = {
    className: `form-control ${config?.className || 'form-control-order'} ${
      config?.disabled ? 'disabled' : ''
    } ${
      config?.input_size
        ? `form-control-${config?.input_size} text-${
            config?.input_size !== 'xl' ? config?.input_size : ''
          }`
        : ''
    }`,

    defaultValue: defaultValue,
    // value: defaultValue,
    id: config?.id ?? null,
    multiple: config?.multiple ? config?.multiple : false,
    disabled: config?.disabled ? config?.disabled : false,
    onChange: (e) => {
      handleChange(e);
      activeOnReload && onReload(true);
    },
  };

  // map options from provided data
  const option =
    options &&
    options?.length !== 0 &&
    options?.map((item, i) => {
      return (
        <option key={!item?.key ? i + item?.label : item?.key + item?.label} value={item?.value}>
          {item?.label}
        </option>
      );
    });

  // return select input

  return (
    <>
      {config?.label ? (
        <label
          htmlFor={config?.id ?? null}
          className={`font-weight-${config?.label_weight ?? 'normal'}`}
        >
          {config?.label_size ? (
            <span className={`text-${config?.label_size}`}>{config?.label_text}</span>
          ) : (
            config?.label_text
          )}
        </label>
      ) : null}
      {(loading && (
        <span
          className={
            config?.input_size
              ? `form-control text-center overflow-hidden form-control-${config?.input_size} text-${
                  config?.input_size !== 'xl' ? config?.input_size : ''
                }`
              : 'form-control text-center overflow-hidden'
          }
          style={{ backgroundColor: '#f0f0f0', cursor: 'not-allowed' }}
        >
          <i className='fa fa-spinner spin'></i> Carregando Filtros...
        </span>
      )) ||
        (loading === false && !option && (
          <span
            className={
              config?.input_size
                ? `form-control text-center overflow-hidden form-control-${
                    config?.input_size
                  } text-${config?.input_size !== 'xl' ? config?.input_size : ''}`
                : 'form-control text-center overflow-hidden'
            }
          >
            {errorMessage ? errorMessage : emptyMessage ? emptyMessage : `Nenhum filtro disponível`}
          </span>
        )) ||
        (!config?.multiple && (
          <>
            <select
              {...inputProps}
              key={key ? key : null}
              {...config?.register}
              // value={option?.length !== 0 && defaultValue && defaultValue}
              defaultValue={option?.length !== 0 && defaultValue ? defaultValue : ''}
            >
              {
                <>
                  {config?.placeholder && (
                    <option value={[]}>{config?.placeholder || 'Placeholder'}</option>
                  )}

                  {option}
                </>
              }
            </select>
          </>
        )) || (
          <Select
            key={key}
            mode='multiple'
            loading={true}
            virtual={false}
            autoClearSearchValue={true}
            showSearch
            className='col-12'
            size='large'
            optionFilterProp='label'
            options={
              options &&
              options.length &&
              options.map((item) => ({
                label: item?.label,
                value: item?.value,
              }))
            }
            onChange={(value) => {
              onChange(value, key);

              activeOnReload && onReload(true);
            }}
            placeholder={config?.placeholder || 'Selecione'}
          />
        )}
    </>
  );
};

// set prop types
FilterSelect.propTypes = {
  onChange: P.func,
  config: P.shape({
    label: P.bool,
    label_text: P.string,
    label_weight: P.string,
    label_size: P.string,
    className: P.string,
    disabled: P.bool,
    register: P.oneOfType([P.func, P.bool, P.any]),
    defaultValue: P.oneOfType([P.string, P.number]),
    id: P.string,
    multiple: P.bool,
    placeholder: P.string,
    input_size: P.string,
  }),
  options: P.oneOfType([P.bool, P.array]),
  key: P.string,
  loading: P.bool,
  errorMessage: P.string,
  onReload: P.func,
  activeOnReload: P.bool,
  emptyMessage: P.string,
};
// export component
export default FilterSelect;
