export const AUTH_LOAD = 'AUTH_LOAD';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const CONTENT_LOAD = 'CONTENT_LOAD';
export const CONTENT_SUCCESS = 'CONTENT_SUCCESS';
export const CLIENT_CREATE_SUCCESS = 'CLIENT_CREATE_SUCCESS';
export const CLIENT_ID_SUCCESS = 'CLIENT_ID_SUCCESS';
export const CLIENT_LIST_SUCCESS = 'CLIENT_LIST_SUCCESS';
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS';
export const CLIENT_LIST_FAIL = 'CLIENT_LIST_FAIL';
export const DISTRITOS_LIST_SUCCESS = 'DISTRITOS_LIST_SUCCESS';
export const DISTRITOS_LIST_ALL_SUCCESS = 'DISTRITOS_LIST_ALL_SUCCESS';
export const DISTRITOS_CAD_SUCCESS = 'DISTRITOS_CAD_SUCCESS';
export const DISTRITOS_UPDATE_SUCCESS = 'DISTRITOS_UPDATE_SUCCESS';
export const DISTRITOS_DELETE_SUCCESS = 'DISTRITOS_DELETE_SUCCESS';
export const EQUIPAMENTO_LISTA_SUCCESS = 'EQUIPAMENTO_LISTA_SUCCESS';
export const EQUIPAMENTO_ID_SUCCESS = 'EQUIPAMENTO_ID_SUCCESS';
export const EQUIPAMENTO_CREATE_SUCCESS = 'EQUIPAMENTO_CREATE_SUCCESS';
export const EQUIPAMENTO_UPDATE_SUCCESS = 'EQUIPAMENTO_UPDATE_SUCCESS';
export const EQUIPAMENTO_DELETE_SUCCESS = 'EQUIPAMENTO_DELETE_SUCCESS';
export const CONTACT_CLIENT_SUCCESS = 'CONTACT_CLIENT_SUCCESS';
export const CONTRATO_CLIENT_SUCCESS = 'CONTRATO_CLIENT_SUCCESS';
export const CONTRATO_ID_SUCCESS = 'CONTRATO_ID_SUCCESS';
export const GROUP_SUCCESS = 'GROUP_SUCCESS';
export const GRUPO_X_SETOR_LIST = 'GRUPO_X_SETOR_LIST';
export const GROUP_X_SETOR_LOAD = 'GROUP_X_SETOR_LOAD';
export const GROUP_LOAD = 'GROUP_LOAD';
export const GROUP_ID_SUCCESS = 'GROUP_ID_SUCCESS';
export const LAYOUT_LOAD = 'LAYOUT_LOAD';
export const LAYOUT_SUCCESS = 'LAYOUT_SUCCESS';
export const LOGIN_LOAD = 'LOGIN_LOAD';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const MEDIDOR_CLIENT_SUCCESS = 'MEDIDOR_CLIENT_SUCCESS';
export const MACROMEDIDOR_CAD_SUCCESS = 'MACROMEDIDOR_CAD_SUCCESS';
export const MACROMEDIDOR_UPDATE_SUCCESS = 'MACROMEDIDOR_UPDATE_SUCCESS';
export const MICROMEDIDOR_CAD_SUCCESS = 'MICROMEDIDOR_CAD_SUCCESS';
export const MICROMEDIDOR_UPDATE_SUCCESS = 'MICROMEDIDOR_UPDATE_SUCCESS';
export const PARSHALL_CAD_SUCCESS = 'PARSHALL_CAD_SUCCESS';
export const PARSHALL_UPDATE_SUCCESS = 'PARSHALL_UPDATE_SUCCESS';
export const PERMISSIONS_FAIL = 'PERMISSIONS_FAIL';
export const PERFIL_SUCCESS = 'PERFIL_SUCCESS';
export const PERFIL_CAT_SUCCESS = 'PERFIL_CAT_SUCCESS';
export const PERFIL_LOAD = 'PERFIL_LOAD';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PERMISSIONS_SUCCESS = 'PERMISSIONS_SUCCESS';
export const PERMISSIONS_LOAD = 'PERMISSIONS_LOAD';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_LOAD = 'REGISTER_USER_LOAD';
export const SETOR_SUCCESS = 'SETOR_SECCESS';
export const SETOR_ADD_SUCCESS = 'SETOR_ADD_SUCCESS';
export const SETOR_ALL_SUCCESS = 'SETOR_ALL_SUCCESS';
export const SETOR_DELETE_SUCCESS = 'SETOR_DELETE_SUCCESS';
export const SETOR_ID_SUCCESS = 'SETOR_ID_SUCCESS';
export const SETOR_UPDATE_SUCCESS = 'SETOR_UPDATE_SUCCESS';
export const TIPO_EQUIPAMENTO_LIST_SUCCESS = 'TIPO_EQUIPAMENTO_LIST_SUCCESS';
export const TRANSMISSOR_PRESSAO_CAD_SUCCESS = 'TRANSMISSOR_PRESSAO_CAD_SUCCESS';
export const TRANSMISSOR_PRESSAO_UPDATE_SUCCESS = 'TRANSMISSOR_PRESSAO_UPDATE_SUCCESS';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_LOAD = 'USER_UPDATE_LOAD';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_LOAD = 'USER_LOAD';
export const USER_CAD_SUCCESS = 'USER_CAD_SUCCESS';
export const UNIDADE_MEDIDA_SUCCESS = 'UNIDADE_MEDIDA_SUCCESS';
export const VRP_DETALHES_SUCCESS = 'VRP_DETALHES_SUCESS';
export const VRP_CAD_SUCCESS = 'VRP_CAD_SUCCESS';
export const VRP_UPDATE_SUCCESS = 'VRP_UPDATE_SUCCESS';
export const FAIL = 'FAIL';

export const abortController = new AbortController();
