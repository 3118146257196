/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from 'react';
import P from 'prop-types';
import NavItems from './navItems';
import { AuthContext } from '../../../config/context/AuthContext';
import { HasValue } from '../../../config/modules/HasValue';

const Nav = ({ nav }) => {
  const authCtx = useContext(AuthContext);
  const [permissionsUser, setPermissionsUser] = useState([]);

  const {
    userData: { user_profile },
  } = authCtx;
  useEffect(() => {
    if (permissionsUser?.length === 0 && user_profile !== undefined) {
      if (user_profile?.permissions_users?.length !== 0) {
        setPermissionsUser(user_profile?.permissions_users);
      }
    }
  }, [permissionsUser, user_profile]);

  const navItensSort = nav.attributes.navigation_items.data.sort((a, b) =>
    a.attributes.title.localeCompare(b.attributes.title),
  );

  const hasMenuItems = navItensSort.some((itens) => {
    return (
      HasValue(permissionsUser, 'ref', itens.attributes.ref) ||
      user_profile?.title === 'Administrador'
    );
  });

  // const body = document.querySelector('body');
  return (
    <>
      {
        nav && nav.attributes.type === 'treeview' && hasMenuItems && (
          <NavItems
            link='#'
            titleLink={nav.attributes.title}
            addClassName='menu-tree text-lg'
            treeview
            icon={nav.attributes.icon}
          >
            {navItensSort.map((itens, index) => {
              const validMenu =
                HasValue(permissionsUser, 'ref', itens.attributes.ref) ||
                user_profile?.title === 'Administrador';
              if (validMenu) {
                return (
                  <NavItems
                    key={index}
                    link={`/${itens.attributes.slug}`}
                    titleLink={itens.attributes.title}
                    icon={itens.attributes.icon}
                    onClick={() => {
                      // body.classList.add('sidebar-collapse');
                      // body.classList.remove('sidebar-open');
                    }}
                  />
                );
              }
            })}
          </NavItems>
        )
        // ) : (
        //   <NavItems link={nav.link} titleLink={nav.title} icon={nav.icon} />
        // )}
      }
    </>
  );
};

export default Nav;

Nav.propTypes = {
  nav: P.oneOfType([P.array, P.object]),
  link: P.string,
  titleLink: P.string,
};
