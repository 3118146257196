import { useAuth0 } from '@auth0/auth0-react';
import { Logout } from '../../config/modules/helpers';

const LogoutOAuth = () => {
  const { logout } = useAuth0();

  return (
    <div className='d-flex justify-content-between p-3'>
      <button className='btn btn-danger btn-flat'>Cancelar</button>
      <button
        className='btn btn-primary btn-flat'
        onClick={() => {
          logout({ returnTo: window.location.origin });
          Logout();
        }}
      >
        Sair
      </button>
    </div>
  );
};

const PageLogout = () => {
  return <LogoutOAuth />;
};

export default PageLogout;
