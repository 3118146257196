import React, { useEffect, useState } from 'react';

import { Logout } from '../../config/modules/helpers';

import P from 'prop-types';
import { Modal, BtnModal, ModalBody } from '../Modal';
import { BCol } from '../AllComponents';
import Cookies from 'js-cookie';
import { abortController } from '../../store/types/ContentTypes';
import SwitchCheckbox from '../Forms/SwitchCheckbox';
const NavBar = (props) => {
  const { setThemeDark, themeDark } = props;
  const darkmode = Cookies.get('dark-mode');
  const [buttonDark, setButtonDark] = useState(false);

  /** load theme */
  useEffect(() => {
    var body = document.getElementsByTagName('body');
    let checkboxDark = document.getElementById('darkmode');
    if (darkmode) {
      body[0].classList.add('dark-mode');
      setButtonDark(false);
      setThemeDark(true);
      if (checkboxDark) {
        checkboxDark.checked = true;
      }
    } else {
      body[0].classList.remove('dark-mode');
      setButtonDark(false);
      setThemeDark(false);
    }
    return abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkmode, buttonDark]);

  const handleDarkChange = (e) => {
    if (e) {
      setButtonDark(true);
      Cookies.set('dark-mode', true, { expires: 30, path: '/' });
    } else {
      setButtonDark(true);
      Cookies.remove('dark-mode');
    }
  };

  return (
    <>
      <nav
        className={`main-header navbar navbar-expand text-sm ${
          themeDark ? ' navbar-dark' : ' navbar-light navbar-white'
        }`}
      >
        {/* Left navbar links */}
        <ul className='navbar-nav'>
          <li className='nav-item'>
            <a className='nav-link' data-widget='pushmenu' href='/#' role='button'>
              <i className='fas fa-bars'></i>
            </a>
          </li>
        </ul>
        {/* <ul className='navbar-nav ml-auto'>
          <li className='nav-item d-sm-inline-block'>
            <span className='text-sm text-info>'>{formattedDate}</span>
          </li>
        </ul> */}
        {/* Right navbar links */}
        <ul className='navbar-nav ml-auto'>
          <li className='nav-item d-none d-sm-inline-block'>
            <a href='/' className='nav-link'>
              Home
            </a>
          </li>
          <li className='nav-item d-none d-sm-inline-block'>
            <a href='/#' className='nav-link'>
              Contact
            </a>
          </li>

          <li className='nav-item d-none d-sm-inline-block'>
            <BtnModal target='logoutButton' addClassName={'nav-link'} color={'muted'}>
              Sair
            </BtnModal>
          </li>
        </ul>
        <ul className='navbar-nav ml-2'>
          <li className='nav-item d-flex text-sm '>
            <SwitchCheckbox
              id={'darkmode'}
              defaultChecked={themeDark}
              event={handleDarkChange}
              label={'Dark-mode'}
              color={'info'}
              size={'sm'}
            />
          </li>
        </ul>
      </nav>
      <Modal id='logoutButton' addClassName={'modal-center '} size='md' title={'Logout'}>
        <ModalBody>
          <BCol>
            <div className='card d-flex flex-column '>
              <div className=' card-body text-center'>
                <p>Deseja realmente sair do sistema?</p>
              </div>
              <div className='d-flex justify-content-space-between p-1'>
                <div className='col-md-6'>
                  <button
                    type='button'
                    onClick={() => Logout()}
                    className='btn  btn-danger btn-block'
                  >
                    Sair
                  </button>
                </div>
                <div className='col-md-6'>
                  <button type='button' className='btn btn-default btn-block ' data-dismiss='modal'>
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </BCol>
        </ModalBody>
      </Modal>
    </>
  );
};

export default NavBar;
NavBar.propTypes = {
  setThemeDark: P.func,
  themeDark: P.bool,
};
