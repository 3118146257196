import React from 'react';
import Header from './Header/Header';
import { Aside } from './Sidebar/Sidebar';
import Footer from './Footer/Footer';
import AuthProvider from '../../store/providers/AuthProvider/AuthProvider';
import CompanyProvider from '../../store/providers/CompanyProvider';
import ContentWrapper from './Content/Content';
import useDinamicComponent from '../hooks/useDinamicComponent';
import TemplateProvider from './store/provider/TemplateProvider';

const Template = () => {
  const [themeDark, setThemeDark] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);

  return (
    <AuthProvider>
      <CompanyProvider>
        <TemplateProvider themeDark={themeDark}>
          <Header
            themeDark={themeDark}
            collapsed={collapsed}
            setThemeDark={setThemeDark}
            setCollapsed={setCollapsed}
          />

          <Aside themeDark={themeDark} />
          <ContentWrapper>{useDinamicComponent()}</ContentWrapper>
          <Footer />
        </TemplateProvider>
      </CompanyProvider>
    </AuthProvider>
  );
};

Template.propTypes = {};

export default Template;
