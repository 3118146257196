import React, { useEffect, useMemo, useRef, useState } from 'react';
import P from 'prop-types';
import { toast } from 'react-toastify';
import { api } from '../../config/modules/api';
/**
 * Custom React hook to handle the resend confirmation email functionality.
 *
 * It manages the state of the input field, error messages, and the countdown timer.
 * It also handles the API request to send the confirmation email.
 *
 * @param {function} cancelAction - a callback function to cancel the resend confirmation email process
 * @return {JSX.Element} a JSX element representing the resend confirmation email form
 */
const useResendConfirmationEmail = (cancelAction) => {
  const inpuEmail = useRef(null);
  const [error, setError] = useState('');
  const [sendSuccess, setSendSuccess] = useState(false);
  const loadTime = sessionStorage.getItem('time');
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [inputBlock, setInputBlock] = useState(false);
  /**
   * Handles the email submission event.
   *
   * Prevents the default form submission behavior and checks if an email address is provided.
   * If an email address is provided, it sends the email using the `sendEmail` function.
   * Otherwise, it displays an error message using `toast.error` and sets the error state using `setError`.
   *
   * @param {SyntheticEvent} e - The email submission event.
   * @return {Promise<void>}
   */
  const handleEmail = async (e) => {
    e.preventDefault();
    if (inpuEmail.current?.value) {
      sendEmail(inpuEmail.current.value);
    } else {
      toast.error('Ocorreu um erro, por favor entre em contato com o administdor do sistema');
      setError('Ocorreu um erro, por favor entre em contato com o administdor do sistema');
    }
  };
  // Request API.
  useEffect(() => {
    if (timeRemaining !== 0) {
      setInputBlock(true);
    } else {
      setInputBlock(false);
    }
  }, [timeRemaining]);
  useEffect(() => {
    if (loadTime || sendSuccess) {
      const now = new Date().getTime();
      const endTime = parseInt(loadTime);
      const remainingTime = endTime - now;

      const interval = setInterval(() => {
        const timeLeft = Math.max(0, remainingTime - (new Date().getTime() - now));

        const seconds = Math.floor((timeLeft % 60000) / 1000);

        const formattedTime = String(seconds).padStart(2, '0');
        !isNaN(parseInt(formattedTime))
          ? setTimeRemaining(parseInt(formattedTime))
          : clearInterval(interval);
      }, 1000);

      return () => clearInterval(interval);
    }
    setSendSuccess(false);
  }, [loadTime, sendSuccess]);

  /**
   * Sends a confirmation email to the user.
   *
   * @param {string} e - The user's email address.
   * @return {Promise<void>}
   */
  async function sendEmail(e) {
    await api
      .post(`/api/auth/send-email-confirmation`, {
        email: e, // user's email
      })
      .then((response) => {
        toast.success('Email de confirmação reenviado com sucesso');
        sessionStorage.setItem('time', new Date().getTime() + 60000);
        console.log('Your user received an email', response);
        setSendSuccess(true);
      })
      .catch((error) => {
        toast.error('Ocorreu um erro, por favor entre em contato com o administdor do sistema');

        console.error('An error occurred:', error.response);
      });
  }

  const inputEmail = useMemo(() => {
    return (
      <div className='form-group col-12'>
        <input
          type='email'
          ref={inpuEmail}
          className='form-control'
          placeholder='Informe seu Email'
          disabled={inputBlock}
        />
        <span className='text-danger text-xs'>{error}</span>
      </div>
    );
  }, [error, inputBlock, inpuEmail]);

  return (
    <>
      <form onSubmit={handleEmail}>
        {inputEmail && inputEmail}
        <div className='d-flex justify-content-center gap-3 col-12 '>
          <button
            type='submit'
            className='btn btn-info btn-flat'
            disabled={timeRemaining && timeRemaining !== 0}
          >
            {`${
              timeRemaining === 0
                ? 'Reenviar email de confirmação'
                : `Nova tentativa em ${timeRemaining}s`
            }`}
          </button>
          <button type='button' className='btn btn-danger btn-flat' onClick={() => cancelAction()}>
            Cancelar
          </button>
          {sendSuccess && (
            <button
              type='button'
              className='btn btn-primary btn-flat'
              onClick={() => cancelAction()}
            >
              Voltar ao Login
            </button>
          )}
        </div>
      </form>
    </>
  );
};

useResendConfirmationEmail.propTypes = {
  cancelAction: P.func,
};

export default useResendConfirmationEmail;
