import * as type from './ContentTypes';

function ReducerLogin(state, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case type.LOGIN_SUCCESS: {
      return { ...state, userData: action.payload, loading: false };
    }
    case type.LOGIN_LOAD: {
      return { ...state, loading: true };
    }
    case type.LOGIN_FAIL: {
      return { ...state, login: false };
    }
  }
}
export default ReducerLogin;
