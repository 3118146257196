import Nav from './nav';
import NavItems from './navItems';
import MenuTreeview from './menuTreeview';
import { useEffect, useState } from 'react';
import { api } from '../../../config/modules/api';
import { Token } from '../../../config/modules/helpers';

export { Nav, NavItems, MenuTreeview };

export const Navigation = () => {
  const token = Token();
  const [dataNav, setDataNav] = useState([]);
  useEffect(() => {
    if (dataNav.length === 0) {
      getNavigation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  async function getNavigation() {
    await api
      .get('/api/navigations?populate=*', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          sort: 'title:asc',
        },
      })
      .then((ress) => setDataNav(ress.data.data))
      .catch((error) => console.log(error.message));
  }
  if (!dataNav) {
    throw new Error('Ocorreu um erro, por favor tente novamente mais tarde!');
  }

  return dataNav;
};
