import { api } from '../../../../config/modules/api';

export async function getTipoEquipamentosPerNameRef(token, tipo_tabela) {
  try {
    const response = await api.get('/api/tipos-equipamentos', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        'filters[tipo_tabela][$in]': tipo_tabela,
      },
    });
    if (response?.status === 200) {
      return response.data.data;
    }
    if (response?.status !== 200) {
      throw response;
    }
  } catch (err) {
    throw err;
  }
}
