import subterraneo from '../layout/img/hidrante/Subterraneo.png';
import coluna from '../layout/img/hidrante/Coluna.png';
export function HidrantIMG(tipo) {
  console.log(tipo);
  console.log(subterraneo, coluna);
  if (tipo === 'Subterraneo') {
    return subterraneo;
  }
  if (tipo === 'Coluna') {
    return coluna;
  }
}
