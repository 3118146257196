import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import CSV from 'sheetjs-style';

export const ExportExcel = ({ excelData, fileName, format }) => {
  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const csvBuffer = CSV.write(wb, { bookType: 'csv', type: 'array' });
    if (format === 'xlsx') {
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
    if (format === 'csv') {
      const fileType = 'text/csv;charset=UTF-8';
      const fileExtension = '.csv';
      const data = new Blob([csvBuffer], { type: fileType });

      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  exportToExcel();
};
