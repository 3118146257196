import React from 'react';
import P from 'prop-types';
import { ExportExcel } from '../../Tables/Excelexport';
import { BtnModal } from '../../Modal';
import { useNavigate } from 'react-router-dom';

/**
 *
 * @param {string} color - Valor padrão String - Adiciona padrões de cores ao componente Ex: primary, danger, warning
 * @param {string} addClassName - Valor padrão String - Adiciona novas classes ao componente  color: P.string,
 * @param {string} className - Valor padrão String - Adiciona novas classes ao componente  color: P.string,
 * @example
 * - primary, secondary, success, warning,
 * @param {object} style - Valor padrão Objeto
 *  @example Ex: {boder:'1px', backgroundColor:'#333'}
 * @returns
 */
const CardHeader = (props) => {
  const navigate = useNavigate();
  const {
    children,
    addClassName,
    styles,
    title,
    tools,
    minus,
    expand,
    back,
    remove,
    Pagination,
    exportExcel,
    excelData,
    exportCsv,
    exportPdf,
    titleDataExport,
    textSize,
    CustomButton,
    addClassTitle,
    className,
    toolSize,
    goBack,
  } = props;

  const tolls = [
    {
      minus: (
        <button
          className={`btn btn-toll ${toolSize ? `btn-${toolSize}` : ''}`}
          type='button'
          data-card-widget='collapse'
          title='Minimizar'
        >
          <i className='fas fa-minus'></i>
        </button>
      ),
      expand: (
        <button
          className={`btn btn-toll ${toolSize ? `btn-${toolSize}` : ''}`}
          type='button'
          data-card-widget='maximize'
          title='Tela inteira'
        >
          <i className='fas fa-expand'></i>
        </button>
      ),
      back: (
        <button
          className='btn btn-toll back btn-sm px-1 py-0 bg-info'
          type='button'
          title='Voltar'
          onClick={() => (goBack ? goBack() : navigate(-1))}
        >
          <i className='fas fa-arrow-left'></i>
        </button>
      ),
      remove: (
        <button
          className={`btn btn-toll ${toolSize ? `btn-${toolSize}` : ''}`}
          type='button'
          data-card-widget='remove'
          title='Remover Card'
        >
          <i className='fas fa-times'></i>
        </button>
      ),
      exportExcel: (
        <button
          type='button'
          title='Exportar em Excel'
          className={`btn btn-toll ${toolSize ? `btn-${toolSize}` : ''} text-success`}
          onClick={() =>
            ExportExcel({
              excelData: excelData,
              fileName: titleDataExport,
              format: 'xlsx',
            })
          }
        >
          <i className='fas fa-file-spreadsheet '></i>
        </button>
      ),
      exportPdf: (
        <BtnModal
          type='button'
          title='Exportar em Excel'
          color={'muted'}
          className={`btn btn-toll ${toolSize ? `btn-${toolSize}` : ''}`}
          target='pdfView'
        >
          <i className='fas fa-file-pdf text-info '></i>
        </BtnModal>
      ),
      exportCsv: (
        <button
          type='button'
          title='Exportar em Excel'
          className={`btn btn-toll ${toolSize ? `btn-${toolSize}` : ''} text-success`}
          onClick={() =>
            ExportExcel({
              excelData: excelData,
              fileName: titleDataExport,
              format: 'csv',
            })
          }
        >
          <i className='fa fa-file-csv p-sm-1'></i>
        </button>
      ),
      Pagination: Pagination,
      Custom: CustomButton,
    },
  ];

  return (
    <div
      className={`card-header${addClassName ? ` ${addClassName}` : ''} ${
        className ? className : ''
      } `}
      style={styles ? styles : {}}
    >
      {title && (
        <div className={`card-title text-${textSize ? textSize : 'md'} text-wrap ${addClassTitle}`}>
          {title}
        </div>
      )}
      {tools && (
        <div
          className='card-tools d-print-none'
          style={
            Pagination
              ? {
                  width: '60%',
                  float: 'right',
                  marginRight: 50,
                  display: 'flex',
                  alignItems: 'center',
                }
              : {}
          }
        >
          {CustomButton && CustomButton}
          {Pagination && Pagination}
          {exportExcel && tolls[0].exportExcel}
          {exportCsv && tolls[0].exportCsv}
          {exportPdf && tolls[0].exportPdf}
          {minus && tolls[0].minus}
          {expand && tolls[0].expand}
          {remove && tolls[0].remove}
          {back && tolls[0].back}
        </div>
      )}
      {children}
    </div>
  );
};

export default CardHeader;
CardHeader.propTypes = {
  children: P.node,
  addClassName: P.string,
  className: P.string,
  title: P.oneOfType([P.string, P.node]),
  tools: P.bool,
  styles: P.object,
  minus: P.bool,
  expand: P.bool,
  back: P.bool,
  goBack: P.func,
  remove: P.bool,
  exportExcel: P.bool,
  exportCsv: P.bool,
  exportPdf: P.bool,
  excelData: P.array,
  Pagination: P.oneOfType([P.bool, P.node]),
  titleDataExport: P.string,
  textSize: P.string,
  CustomButton: P.node,
  addClassTitle: P.string,
  toolSize: P.string,
};
