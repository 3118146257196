import React, { useMemo } from 'react';
import P from 'prop-types';
import { AuthContext } from '../../config/context/AuthContext';
import FilterSelect from '../Forms/FilterSelect/FilterSelect';

/**
 *
 * @param {string} config.className Adiciona classe no componente input
 * @returns
 */
const FilterCompanies = ({ onChange, config }) => {
  const ctxAuth = React.useContext(AuthContext);

  const adm =
    ctxAuth?.userData?.user_profile?.title === 'Administrador' ||
    ctxAuth?.userData?.user_profile?.adm ||
    config?.adm;

  const [companyList, setCompanyList] = React.useState([]);
  const [loadCompanies, setLoadCompanies] = React.useState(false);
  React.useEffect(() => {
    const companyAbortController = new AbortController();
    if (companyList.length === 0) {
      setLoadCompanies(true);

      getCompanyList(companyAbortController);
    }

    return () => companyAbortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adm]);
  async function getCompanyList(companyAbortController) {
    await import('../../config/modules/api').then(({ api }) => {
      api
        .get('/api/companies', {
          signal: companyAbortController.signal,
          headers: {
            Authorization: `Bearer ${ctxAuth.token}`,
          },
          params: {
            pagination: {
              page: 1,
              pageSize: 1000000000000,
            },
            sort: 'company_nome:asc',
          },
        })
        .then((r) => setCompanyList(r.data.data))
        .catch((e) => console.error(e))
        .finally(() => setLoadCompanies(false));
    });
  }
  const handleChangeIntern = (data) => {
    console.error('Onchange está vazia', data);
  };

  return useMemo(() => {
    return (
      <FilterSelect
        loading={loadCompanies}
        options={
          companyList.length !== 0 &&
          companyList
            .sort((a, b) => a?.attributes?.company_nome - b?.attributes?.company_nome)
            .map((c) => ({ label: c?.attributes?.company_nome, value: c.id }))
        }
        config={{
          id: config?.id,
          placeholder: config?.placeholder ?? 'Filtrar por Empresa',
          defaultValue: config?.defaultValue,
          className: config?.className ?? '',
          label: config?.label,
          label_text: config?.label_text,
          label_weight: config?.label_weight,
          label_size: config?.label_size,
          register: config?.register,
          disabled: config?.disabled ?? false,
          input_size: config?.input_size,
        }}
        onChange={onChange ? onChange : handleChangeIntern}
      />
    );
  }, [
    companyList,
    config?.className,
    config?.defaultValue,
    config?.disabled,
    config?.id,
    config?.input_size,
    config?.label,
    config?.label_size,
    config?.label_text,
    config?.label_weight,
    config?.placeholder,
    config?.register,
    loadCompanies,
    onChange,
  ]);
};

FilterCompanies.propTypes = {
  onChange: P.func,

  config: P.shape({
    label: P.bool,
    label_text: P.string,
    label_weight: P.string,
    label_size: P.string,
    className: P.string,
    disabled: P.bool,
    register: P.oneOfType([P.func, P.bool, P.any]),
    defaultValue: P.oneOfType([P.string, P.number]),
    id: P.string,
    multiple: P.bool,
    placeholder: P.string,
    adm: P.bool,
    input_size: P.string,
  }),
};

export default FilterCompanies;
FilterCompanies.FilterCompany = {};
