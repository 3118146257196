import env from './env';
import { apiNode } from './api';
import Cookies from 'js-cookie';

// const SOCKET_SERVER_URL = `${env('SERVER_API_PROTOCOL')}://${env('SERVER_SOCKET_HOST')}${
//   env('SERVER_SOCKET_PORT') ? `:${env('SERVER_SOCKET_PORT')}` : ''
// }`;
// let userDataString = localStorage.getItem('userData');
// let userData = JSON.parse(userDataString);
export const LoginSocket = async (userData) => {
  const secret = env('SERVER_SECRET_KEY');

  if (userData) {
    getIoToken();
  }
  async function getIoToken() {
    // console.log('Efetuando login no SoketIo');
    const response = await apiNode
      .post(`/api/login`, {
        token: secret,
        jwtExpires: '4h',
        user: {
          id: userData?.id,
          name: userData?.name,
          email: userData?.email,
        },
        // jwtExpires:'4h'
      })

      .catch((e) => console.log(e));

    try {
      if (response?.data) {
        const data = response?.data;
        const ioExpires = new Date().getTime() + (14400000 - 1000);
        console.log({ response });
        if (data.token) {
          localStorage.setItem('io', data.token);
          Cookies.set('io', data.token, { expires: new Date(ioExpires) });
          // console.log('Conectado ao socket io com sucesso!');
          return { isAuthenticate: true, jwt: data.token };
        } else {
          return { isAuthenticate: false };
        }
      } else {
        throw new Error('Erro ao Conectar socket io ');
      }
    } catch (error) {
      console.error('Erro ao Conectar socket io ', error);
    }
  }
};
