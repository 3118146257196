/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { abortController } from '../../../store/types/ContentTypes';
import { api } from '../../../config/modules/api';
import env from '../../../config/modules/env';

export const GetFooter = () => {
  const [dataFooter, setDataFooter] = useState([]);

  const setFooter = async () => {
    await api
      .get('/api/footer', {
        headers: {
          Authorization: `Bearer ${env('API_TOKEN')}`,
          'Content-Type': 'application/json',
        },
        params: {
          populate: '*',
        },
      })
      .then((ress) => setDataFooter(ress.data.data))
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    if (dataFooter.length === 0) setFooter();

    return abortController.abort();
  }, []);

  try {
    if (dataFooter) {
      return dataFooter;
    }
  } catch {
    throw new Error('Ocorreu um erro, por favor tente novamente mais tarde!');
  }
  return dataFooter;
};
