import { useContext } from 'react';
import { AuthContext } from '../../config/context/AuthContext';
import HeaderHome from './components/Header/HeaderHome';
import './home.css';
import HomeProvider from './HomeProvider';
import Dashboard from './Dashboard';

const Home = () => {
  const ctxAuth = useContext(AuthContext);
  return (
    <HomeProvider ctxAuth={ctxAuth}>
      <div className=' home-hero'>
        <HeaderHome />

        <div className='flex-grow-1 bg-success position-relative'>
          <Dashboard />
        </div>
      </div>
    </HomeProvider>
  );
};

export default Home;
