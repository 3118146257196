import React, { useContext } from 'react';
import P from 'prop-types';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../config/context/AuthContext';

/**
 *
 * @param {string} title - (Valor String) -- Adiciona titulo ao Content Header
 * @param {array} breadcrumb - (Valor Array) Exemplo de uso
 * @example <ContentHeader breadcrumb={['home','page']}
 * @returns
 */
const ContentHeader = (props) => {
  const { title, breadcrumb, urlImg, titlePosition, className } = props;
  const ctxAuth = useContext(AuthContext);

  return (
    <div className='content-header'>
      <div className='container-fluid'>
        <div className='row mb-2'>
          <div className={`col-lg-12 col-xl-9 mb3 `}>
            <h1
              className={`${titlePosition && titlePosition === 'left'
                ? ' text-md-left text-lg-left'
                : titlePosition === 'right'
                  ? ' text-md-right'
                  : titlePosition === 'center'
                    ? 'text-center text-md-center text-lg-center pl-md-5 ml-md-5'
                    : '' || 'text-center text-md-center text-lg-center pl-md-5 ml-md-5'
                }${className ? ` ${className}` : ''}`}
            >
              {title ? title : ctxAuth?.userData?.company?.company_nome || 'ECOWEB'}
            </h1>
            {urlImg && <img src={urlImg} style={{ maxHeight: 60 }} alt='Logo' />}
          </div>
          <div className='col-xl-3'>
            <ol className='breadcrumb float-lg-right float-md-right'>
              {breadcrumb &&
                breadcrumb.map((b, index) => {
                  return (
                    <li className='breadcrumb-item active' id={index} key={index}>
                      <Link to={`/${b.toLowerCase().split(' ').join('-')}`}>{b}</Link>
                    </li>
                  );
                })}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentHeader;
ContentHeader.propTypes = {
  title: P.string.isRequired,
  breadcrumb: P.array,
  urlImg: P.string,
  titlePosition: P.string,
  className: P.string,
};
