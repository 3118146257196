import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Spin } from 'antd';
import { toast } from 'react-toastify';

import { Card, CardBody, CardHeader } from '../../components/AllComponents';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom/dist';

import '../login/style.css';
const Forgot = () => {
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [searchParams] = useSearchParams();
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [sendReset, setSendReset] = useState(false);
  const navigate = useNavigate();
  const privateCode = searchParams.get('code');
  const {
    handleSubmit,
    register,

    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoader(true);
    await import('../../config/modules/api').then(({ api }) =>
      api
        .post('/api/auth/forgot-password', {
          email: data.email, // user's email
        })
        .then(() => {
          setSendReset(true);
          toast.success(
            'Um email foi enviado com o procedimento de reset, Verifique sua caixa de email! Não esqueça de procurar em span também!',
          );
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          toast.error(
            `Ocorreu um erro, tente novamente mais tarte! Code: ${error.response.data.error.status}`,
          );
        }),
    );
  };
  const onChangePassword = async (data) => {
    setLoader(true);

    if (privateCode) {
      import('../../config/modules/api').then(({ api }) =>
        api
          .post('/api/auth/reset-password', {
            code: privateCode, // code contained in the reset link of step 3.
            password: data.password,
            passwordConfirmation: data.passwordConfirmation,
          })
          .then(() => {
            setPasswordValidation(false);
            toast.success('Senha alterada com sucesso!');
            setLoader(false);
            navigate('/login', { replace: true });
          })
          .catch((error) => {
            toast.error(
              `Erro ao tentar criar uma nova senha Status: ${error.response.data.error.status}`,
            );
            if (error.response.data.error.message === 'Passwords do not match') {
              setPasswordValidation(true);
            }
            setLoader(false);
          }),
      );
    }
  };
  return (
    <div className='login-card'>
      <div className='login-left col-md-6'>
        <div className='area'>
          <ul className='circles'>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <img src='/img/logo-ecometria.png' className='logo' alt='ECOMETRIA' width={'70%'} />
      </div>
      <div className='col-md-6 d-flex flex-wrap align-items-center vh-100 justify-content-center'>
        {(params?.reset && (
          <Card addClassName={'col-md-12'}>
            <CardHeader addClassName={'text-center'}>
              <div className='login-logo'>
                <a href='/#'>
                  <b>ECOWEB - Alterar senha</b>
                </a>
              </div>
            </CardHeader>
            <CardBody addClassName={'login-card-body text-center'}>
              <p> Informe seu email ou usuário para recuperar a senha.</p>
              <form onSubmit={handleSubmit(onChangePassword)}>
                <div className='form-group justify-content-center d-flex flex-column align-items-center'>
                  <label htmlFor='reset'>Nova senha</label>
                  <input
                    type='password'
                    {...register('password', { required: true, minLength: 6 })}
                    className='form-control  col-6'
                    placeholder='Insira a nova senha'
                  />
                  {console.log(errors)}
                  {errors.password?.type === 'minLength' && (
                    <span className='text-danger'>* Digite no mínimo 6 caracteres</span>
                  )}
                  {errors.password?.type === 'required' && (
                    <span className='text-danger'>* Campo obrigatório</span>
                  )}
                  {passwordValidation && (
                    <span className='text-danger'>* As senhas não conferem</span>
                  )}
                </div>

                <div className='form-group justify-content-center d-flex flex-column align-items-center'>
                  <label htmlFor='reset'>Confirme Nova senha</label>
                  <input
                    type='password'
                    {...register('passwordConfirmation', { required: true })}
                    className='form-control  col-6'
                    placeholder='Insira a nova senha'
                  />
                  {errors.passwordConfirm?.type === 'minLength' && (
                    <span className='text-danger'>* Digite no mínimo 6 caracteres</span>
                  )}
                  {errors.passwordConfirm?.type === 'required' && (
                    <span className='text-danger'>* Campo obrigatório</span>
                  )}
                  {passwordValidation && (
                    <span className='text-danger'>* As senhas não conferem</span>
                  )}
                </div>
                <div className='form-group mt-3'>
                  <button
                    type='submit'
                    className='btn btn-info btn-flat'
                    disabled={loader ? true : false}
                  >
                    {loader ? <Spin /> : ''}Enviar
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary btn-flat ml-2'
                    onClick={() => navigate('/login', { replace: true })}
                  >
                    Ir para Login
                  </button>
                </div>
              </form>
            </CardBody>
          </Card>
        )) || (
          <Card addClassName={'col-md-12'}>
            <CardHeader addClassName={'text-center'}>
              <div className='login-logo'>
                <a href='/#'>
                  <b>ECOWEB - Recuperar Senha</b>
                </a>
              </div>
            </CardHeader>
            <CardBody addClassName={'login-card-body text-center'}>
              {(!sendReset && (
                <>
                  {' '}
                  <p> Informe seu email ou usuário para recuperar a senha.</p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-group justify-content-center d-flex flex-column align-items-center'>
                      <label htmlFor='reset'>Usuario/Email</label>
                      <input
                        type='email'
                        {...register('email')}
                        className='form-control  col-6'
                        placeholder='Digite seus dados'
                      />
                    </div>
                    <div className='form-group mt-3'>
                      <button
                        type='submit'
                        className='btn btn-info btn-flat'
                        disabled={loader ? true : false}
                      >
                        {loader ? <Spin /> : ''} Enviar
                      </button>
                      <button
                        type='button'
                        className='btn btn-primary btn-flat ml-2'
                        onClick={() => navigate('/login', { replace: true })}
                      >
                        Ir para Login
                      </button>
                    </div>
                  </form>
                </>
              )) || (
                <p>
                  Um link foi enviado para seu email. Acesse seu Email e clique no link para
                  prosseguir com a alteração de senha!
                </p>
              )}
            </CardBody>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Forgot;
