/* eslint-disable no-unused-vars */
import React from 'react';
import '../style.css';
import { Card, CardBody, CardHeader } from '../../../components/AllComponents';

import { Link } from 'react-router-dom';
const ConfirmLogin = () => {
  // const [formValue, setformValue] = useState();
  //configuration Layout tag body
  const el = document.getElementsByTagName('body');
  if (el) {
    el[0].classList.remove('sidebar-mini');
    el[0].classList.add('hold-transition');
    // el[0].classList.add("login-page");
  }

  // form data change
  // const handleChange = useCallback(
  //   function (value) {
  //     setformValue((c) => ({ ...c, [value.target.name]: value.target.value }));
  //   },
  //   [setformValue],
  // );

  return (
    <div className='login-card'>
      <div className='login-left col-md-6'>
        <div className='area'>
          <ul className='circles'>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <img src='/img/logo-ecometria.png' className='logo' alt='ECOMETRIA' width={'70%'} />
      </div>
      <Card addClassName={'col-md-6'}>
        <CardHeader addClassName={'text-center'}>
          <div className='login-logo'>
            <a href='/#'>
              <b>ECOWEB</b>
            </a>
          </div>
        </CardHeader>
        <CardBody addClassName={'login-card-body text-center'}>
          <p> Sua conta foi confimada com sucesso.</p>
          <p>
            <Link to={'/login'} className='btn btn-info'>
              clique aqui para efetuar o login
            </Link>
          </p>
        </CardBody>
      </Card>
    </div>
  );
};

export default ConfirmLogin;
