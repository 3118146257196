/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import P, { bool } from 'prop-types';

import { useParams } from 'react-router-dom';
import { api } from '../../config/modules/api';

import { AuthContext } from '../../config/context/AuthContext';
import { useForm } from 'react-hook-form';

const CheckBox = (props) => {
  const authContext = useContext(AuthContext);
  const { userData, token } = authContext;

  const page = useParams();

  const user = userData;
  const [dataupdate, setDataUpdate] = useState(false);
  const [checkboxSet, setCheckbox] = useState(false);
  const [urlParams, setUrlParams] = useState(false);
  const [permissionSet, setPermissionSet] = useState([]);

  const checkboxEl = document.querySelectorAll('.input-checkbox-controller');
  const { handleSubmit, register, setValue } = useForm();

  let permissions_users = [];

  function getCheckbox() {
    if (checkboxEl) {
      for (let i = 0; i < checkboxEl.length; i++) {
        if (checkboxEl[i].checked) {
          console.log({ checkboxEl: checkboxEl[i] });

          permissions_users.push(parseInt(checkboxEl[i].value));
        }
      }
    }
    setPermissionSet(permissions_users);
  }

  useEffect(() => {
    if (checkboxSet) {
      getCheckbox();
      setCheckbox(false);
    }
  }, [checkboxSet]);

  useEffect(() => {
    if ((page.slug === 'perfil', page.params === 'type' && page.value)) {
      setUrlParams(page.value);
    }
  }, []);
  //setProfiles
  const [profile, setProfile] = useState({
    title: '',
    value: '',
    state: bool,
  });

  const handleCheckboxChange = async (e) => {
    setProfile((preventValues) => ({
      ...preventValues,
      title: e.target.name,
      value: e.target.value,
      state: e.target.checked,
    }));

    if (page.slug === 'perfil') {
      const options = {
        method: 'PUT',
        url: `/api/user-profiles/${urlParams ? parseInt(urlParams) : ''}`,
        params: { populate: 'deep' },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: { data: { permissions_users: permissions_users } },
      };

      await import('../../config/modules/api')
        .then(({ api }) => api.request(options))
        .then(function () {
          setDataUpdate(true);
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  };
  // console.log(page.value);
  // console.log(profile);

  useEffect(() => {
    if (dataupdate) {
      setDataUpdate(true);
    }
  }, [dataupdate]);

  function submit(data) {
    if (data.checked) {
      setPermissionSet((prev) => [
        {
          ...prev,

          value: data.value,
        },
      ]);
    }
  }

  return (
    <div className='icheck-info'>
      <input
        name={props.name}
        type='checkbox'
        id={props.id + props.name}
        value={parseInt(props.value)}
        onInput={(e) => handleCheckboxChange(e)}
        onChange={(e) => handleCheckboxChange(e)}
        defaultChecked={props.status}
        className='input-checkbox-controller'
        style={{ display: 'block' }}
        {...register('permissions_users', {
          onChange: (e) => handleSubmit(submit(e.target)),
        })}
      />

      {props.label && (
        <label htmlFor={props.id + props.name}>
          {props.label} {dataupdate && <i className='fas fa-check text-success'></i>}
        </label>
      )}
    </div>
  );
};

export default CheckBox;
CheckBox.propTypes = {
  name: P.string,
  status: P.bool,
  id: P.number,
  value: P.node,
  onEvent: P.func,
  label: P.node,
};
