import React, { useEffect } from 'react';
import P from 'prop-types';
import { useState } from 'react';
import './loading.css';

const Loading = (props) => {
  const {
    height,
    wight,
    justifyContent,
    position,
    alignItems,
    message,
    size,
    className,
    text,
    colorSpinn,
  } = props;
  const [timer, setTimer] = useState(false);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimer(true);
    }, 300000);

    return () => setTimeout(timer);
  }, [timer]);
  useEffect(() => {
    if (timer) setMsg(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  if (!timer) {
    return (
      <div
        className={`d-flex justify-content-center align-items-center gap-2 ${
          (className && className) || ''
        }`}
        style={{
          position: position || 'absolute',
          zIndex: 100000,
          width: wight || '100%',
          height: height || '90vh',
        }}
      >
        {/* Spinner */}

        <i
          className={`fas fa-spinner-third text-${colorSpinn ? colorSpinn : 'info'} spin text-${
            size ? size : 'xl'
          } `}
        ></i>
        {msg}
        {text}
      </div>
    );
  }
  return (
    <div
      className='d-flex'
      style={{
        justifyContent: justifyContent,
        alignItems: alignItems,
        width: wight,
        height: height,
      }}
    >
      {msg}
    </div>
  );
};

export default Loading;
Loading.propTypes = {
  height: P.oneOfType([P.string, P.number]),
  wight: P.oneOfType([P.string, P.number]),
  justifyContent: P.string,
  alignItems: P.string,
  message: P.string,
  className: P.string,
  text: P.string,
  size: P.string,
  position: P.string,
  colorSpinn: P.string,
};
