export const useKeyValues = () => {
  document.addEventListener('keyup', (e) => {
    keyHandler(e);
    e.preventDefault();
  });

  const keyHandler = (e) => {
    if (e.key === 'n') {
      return (
        e.altKey &&
        document.getElementById('keyEnter') &&
        document.getElementById('keyEnter').click()
      );
    }
  };
};
