import React from 'react';
import P from 'prop-types';

/**
 *
 * @param {string} color - Valor padrão String - Adiciona padrões de cores ao componente Ex: primary, danger, warning
 * @param {string} addClassName - Valor padrão String - Adiciona novas classes ao componente  color: P.string,
 * @param {string} className - Valor padrão String - Adiciona novas classes ao componente  color: P.string,
 * @example
 * - primary, secondary, success, warning,
 * @param {object} style - Valor padrão Objeto
 *  @example Ex: {boder:'1px', backgroundColor:'#333'}
 * @returns
 */
const CardBody = (props) => {
  const { className, addClassName, style } = props;

  return (
    <div
      className={`card-body${addClassName ? ` ${addClassName}` : ''} ${className ? className : ''}`}
      style={(style && style) || {}}
    >
      {props.children}
    </div>
  );
};

export default CardBody;
CardBody.propTypes = {
  children: P.node,
  addClassName: P.string,
  style: P.object,
  className: P.string,
};
