/* eslint-disable no-unused-vars */
import { useEffect, useReducer, useState } from 'react';
import { SetCookies } from '../../../config/modules/helpers';
import ReducerLogin from '../../../config/reducers/reducerLogin';
import { userData } from '../../../config/reducers/data';
import { loadLogin } from '../../../config/actions/login';
import { api } from '../../../config/modules/api';
import { useNavigate, useLocation } from 'react-router-dom';
import { generateCodeTfa } from './generateCodeTfa';
import { abortController } from '../../../store/types/ContentTypes';
// import { LoginSocket } from '../../../config/modules/loginSocket';
import { UserCompany } from './UserCompany';
import { PermissionsUser } from './PermissionsUser';

export function Validation(data, setLoadingLogin, state, modalOpen, setResendToken) {
  console.log({ Validation: state });
  const location = useLocation();

  const [loginState, loginDispatch] = useReducer(ReducerLogin, userData);
  const [tokenInitial, setTokenInitial] = useState([]);
  const { jwt, user, expires_in, expireLogin } = data;

  //Validação de usuário no banco
  useEffect(() => {
    const abortControll = new AbortController();

    async function fetchData() {
      if (jwt) {
        await loadLogin(loginDispatch, '/api/users/me', {
          headers: { Authorization: `Bearer ${jwt}`, params: { populate: '*' } },
        })
          .then((dispatch) => {
            setTokenInitial(jwt);
            dispatch();
          })

          .catch((error) => {
            //  window.location.replace("/login");
            setResendToken(true);
            console.log(error.message);
            return false;
          });
      }
    }
    fetchData();
    return abortControll.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);
  const navigate = useNavigate();

  //Checagem se usuário possui autenticação em dois fatores
  useEffect(() => {
    if (tokenInitial.length !== 0) {
      if (user) {
        const { id, email, tfa } = user;
        // LoginSocket(user);
        if (tfa) {
          // Criar um objeto Date para a data e hora atual
          const dataHoraAtual = new Date();

          // Adicionar 5 minutos à data e hora atual
          dataHoraAtual.setMinutes(dataHoraAtual.getMinutes() + 5);

          // Formatar a nova data e hora
          const ano = dataHoraAtual.getFullYear();
          const mes = String(dataHoraAtual.getMonth() + 1).padStart(2, '0');
          const dia = String(dataHoraAtual.getDate()).padStart(2, '0');
          const horas = String(dataHoraAtual.getHours()).padStart(2, '0');
          const minutos = String(dataHoraAtual.getMinutes()).padStart(2, '0');

          const novaDataHoraFormatada = `${ano}-${mes}-${dia} ${horas}:${minutos}`;

          const code = generateCodeTfa(6);

          api
            .get('/api/tfas', {
              headers: { Authorization: `Bearer ${jwt}` },
              params: {
                'filters[users_permissions_user][id][$eq]': id,
                populate: 'users_permissions_user',
              },
            })
            .then(async (r) => {
              if (r.data.data) {
                const regenereCode = generateCodeTfa(6);
                await api
                  .put(
                    `/api/tfas/${parseInt(r.data.data[0].id)}`,
                    {
                      data: {
                        code: regenereCode,

                        expires: novaDataHoraFormatada,
                      },
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${jwt}`,
                      },
                    },
                  )
                  .then(async (r) => {
                    if (r.data.data.id) {
                      await navigate('/codverifield', {
                        state: { id: r.data.data.id, jwt: jwt },
                        replace: true,
                      });
                    }
                  })

                  .catch((e) => console.log(e));
              }
            })

            .catch(async (e) => {
              await api
                .post(
                  `/api/tfas/`,
                  {
                    data: {
                      code,
                      email: email,
                      expires: novaDataHoraFormatada,
                      users_permissions_user: id,
                    },
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${jwt}`,
                    },
                  },
                )
                .then(async (r) => {
                  // LoginSocket();
                  if (r.data.data.id) {
                    await navigate('/codverifield', {
                      state: { id: r.data.data.id, jwt: jwt },
                      replace: true,
                    });
                  }
                })

                .catch((e) => console.log(e));
            });

          /*Processo autenticação em dois fatores*/
        } else {
          /* Salva perfil do usuário no localstorage */
          PermissionsUser(user.id, jwt)
            .then((r) => {
              localStorage.setItem(
                'user_profile',
                JSON.stringify({
                  title: r.data.data[0].attributes?.title,
                  permissions_users: r.data.data[0].attributes?.permissions_users,
                }),
              );
            })
            .catch((e) => console.error(e));
          /* Salva perfil do usuário no localstorage */
          localStorage.setItem('userData', JSON.stringify(user));

          UserCompany(user.id, jwt)
            .then((r) => {
              localStorage.setItem(
                'user_company',
                JSON.stringify({
                  id: r.data.data[0]?.id,
                  attributes: {
                    company_nome: r.data.data[0]?.attributes?.company_nome,
                    user_groups: { data: r.data.data[0]?.attributes?.user_groups?.data },
                  },
                }),
              );
            })
            .catch((e) => console.error(e));

          localStorage.setItem('tk_uac', jwt, expires_in * 1000);
          // localStorage.setItem('user_profile', JSON.stringify(_permissionsUser));
          // LoginSocket(user);
          // console.log(_permissionsUser);
          /*Realiza autenticação confirmada do usuário sem dois fatores*/
          SetCookies('tk_uac', jwt, expires_in * 1000);
          SetCookies('expL', expireLogin * 1000, expires_in * 1000);
          SetCookies('token_expires', expires_in, expires_in * 1000);
          // setTimeout(async () => {
          //   await setLoadingLogin(false);
          //   return LoginSocket();
          // }, 300);
          state?.key && console.log('Login efetuado com sucesso', state?.key);
          state?.key && modalOpen(false);
          state?.key
            ? navigate(`${state?.key}`, { reload: true })
            : location.state?.key
            ? navigate(location.state.key !== '/' ? -1 : location.state.key, {
                relative: 'path',
                replace: true,
              })
            : navigate('/');

          // window.location.href = '/';
        }
      }
    }
    return abortController.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenInitial, jwt, user]);
}
