import React from 'react';
import P from 'prop-types';
import { CloseModal } from './closeModal';
import ReactDOM from 'react-dom';

/**
 *
 * @param {String} addClassName - adiciona novas classes css
 * @param {string} id - Id do modal para captura do botão
 * @example - Exemplo de uso
 * <BtnModal id='modal'></BtnModal>
 * <Modal id='modal'></Modal>
 * @param {string} title - Adiciona titulo ao modal-title
 * @param {boolean} ModalFooter - Adiciona Footer ao Modal
 * @param {string} btnCloseTitle -- Formata o texto do botão Close -- Use quando o ModalFooter for true
 * @param {string} btnSubmitTitle -- Formata o texto do botão Submit -- Use quando o ModalFooter for true
 * @param {string} size
 * @param {string} position -- posição vertical do modal exemplo modal-dialog-center
 * @example - Ex: size='xl' :Extra large Modal max-width 1140px size='xxl' :Extra large Modal max-width 1400px
 * @param {string} styleBtnSubmit -- Altera cor do botão Submit
 * @example - Exemplo de uso
 * <Modal styleBtnSubmit='info'> retorna - <button style={{bg-info}}></button>
 */
const Modal = (props) => {
  const {
    addClassName,
    id,
    title,
    children,
    btnCloseTitle,
    btnSubmitTitle,
    styleBtnSubmit,
    size,
    ModalFooter,
    style,
    elevation,
    position,
    contentHeader,
    fade,
    styleTitle,
    classTitle,
    height,
    minHeight,
    form,
    onSubmit,
    closeModal,
  } = props;

  return ReactDOM.createPortal(
    <div
      className={`modal${addClassName ? ` ${addClassName}` : ''}${
        fade ? ' fade' : ' fade'
      } overflow-auto`}
      id={id}
      style={style}
      data-backdrop={'static'}
      data-keyboard={true}
      data-focus={true}
    >
      <div
        className={`modal-dialog ${size ? `modal-${size}` : ''} ${
          position ? ` modal-dialog-${position}` : ' modal-dialog-centered'
        }`}
      >
        <div
          className={`modal-content ${elevation ? `elevation-${elevation}` : ''} `}
          style={{ height, minHeight }}
        >
          <div className='modal-header'>
            <h4 className={`modal-title ${classTitle ? classTitle : ''}`} style={{ styleTitle }}>
              {title}
            </h4>
            {contentHeader}
            <button
              type='button'
              className='close'
              onClick={() => {
                CloseModal(id || 'modal');
                closeModal && closeModal(false);
              }}
              aria-label='Close'
            >
              <span aria-hidden='true'>x</span>
            </button>
          </div>
          {(form && (
            <form onSubmit={onSubmit}>
              {children}
              <div className='modal-footer align-items-right justify-content-between'>
                {btnSubmitTitle && (
                  <button
                    type='submit'
                    className={`btn ${styleBtnSubmit ? `btn-${styleBtnSubmit}` : 'btn-info'} `}
                  >
                    {btnSubmitTitle ? btnSubmitTitle : 'Adicionar'}
                  </button>
                )}
                <button
                  type='button'
                  className={`btn btn-outline-light`}
                  onClick={() => CloseModal(id ?? 'modal')}
                >
                  {btnCloseTitle ? btnCloseTitle : ''}
                </button>
              </div>
            </form>
          )) || (
            <>
              {children}
              {ModalFooter && (
                <div className='modal-footer align-items-right justify-content-between'>
                  {btnSubmitTitle && (
                    <button
                      type='submit'
                      className={`btn ${styleBtnSubmit ? `btn-${styleBtnSubmit}` : 'btn-info'} `}
                    >
                      {btnSubmitTitle ? btnSubmitTitle : 'Adicionar'}
                    </button>
                  )}
                  <button
                    type='button'
                    className={`btn btn-outline-light`}
                    onClick={() => {
                      CloseModal(id ?? 'modal');
                      closeModal && closeModal(false);
                    }}
                  >
                    {btnCloseTitle ? btnCloseTitle : ''}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>,
    document.getElementById('rootModal'),
  );
};

export default Modal;
Modal.propTypes = {
  addClassName: P.string,

  id: P.string.isRequired,
  title: P.string,
  children: P.node,
  btnCloseTitle: P.string,
  btnSubmitTitle: P.string,
  styleBtnSubmit: P.string,
  position: P.string,
  elevation: P.string,
  size: P.string,
  style: P.object,
  ModalFooter: P.bool,
  fade: P.bool,
  contentHeader: P.oneOfType([P.string, P.node]),
  styleTitle: P.object,
  classTitle: P.string,
  height: P.oneOfType([P.number, P.string]),
  minHeight: P.oneOfType([P.number, P.string]),
  form: P.bool,
  onSubmit: P.func,
  closeModal: P.func,
};
