import { apiUrl } from '../../../config/modules/api';

export class HomeMapModelEquipamentos {
  constructor(equipamento) {
    this.id = equipamento?.id;
    this.attributes = equipamento?.attributes;
    this.tipo_equipamento = {
      tipo: equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_nome,
      thumb:
        equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_img?.data?.attributes
          ?.formats?.thumbnail?.url ||
        equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_img?.data?.attributes
          ?.url,
      img: equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_img?.data?.attributes
        ?.url,
      color:
        equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
        'detalhe_macromedidor'
          ? 'text-danger'
          : equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
            'detalhe_calhaparshall'
          ? 'text-warning'
          : equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
            'detalhe_palmer'
          ? 'text-purple'
          : equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
            'reservatorio'
          ? 'text-primary'
          : equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
            'detalhe_transmissor_pressao'
          ? 'text-orange'
          : equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
            'detalhe_vrp'
          ? 'text-success'
          : equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
            'detalhe_hidrante'
          ? 'text-danger'
          : equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
            'detalhe_registrofechamento'
          ? 'text-primary-default'
          : equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
            'detalhe_registrodescarga'
          ? 'text-marron'
          : 'text-secondary',
      icon:
        equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
        'detalhe_hidrante'
          ? 'fire-hydrant'
          : 'location-dot',
    };
    this.link = equipamento?.attributes?.daee
      ? `/gestao-daee/id/${equipamento?.attributes?.detalhe_macromedidor?.data?.id}`
      : equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
        'detalhe_macromedidor'
      ? `/gestao-medidor-vazao/id/${equipamento?.attributes?.detalhe_macromedidor?.data?.id}`
      : equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
        'detalhe_calhaparshall'
      ? `/gestao-de-calhas/calha/${equipamento?.attributes?.detalhe_calha?.data?.id}`
      : equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
        'detalhe_palmer'
      ? `/gestao-de-calhas/calha/${equipamento?.attributes?.detalhe_calha?.data?.id}`
      : equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela === 'reservatorio'
      ? `gestao-de-abastecimento/id/${equipamento?.attributes?.reservatorio?.data?.id}`
      : equipamento?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
          '"detalhe_pluviometro"' && `/gestao-pluviometrico/view/${equipamento?.id}`;
    this.coordinates = [
      parseFloat(equipamento?.attributes.equ_lat),
      parseFloat(equipamento?.attributes.equ_long),
    ];
  }
}

export class SetorMapModel {
  constructor(setor) {
    this.id = setor?.id;
    this.name = setor?.attributes?.setor_nome;
    this.url = apiUrl + setor?.attributes?.setor_kml?.data?.attributes?.url;
  }
}
export class DistritoMapModel {
  constructor(distrito) {
    this.id = distrito?.id;
    this.name = distrito?.attributes?.dmc_nome;
    this.url = apiUrl + distrito?.attributes?.dmc_kml?.data?.attributes?.url;
  }
}

export class SistemaMapModel {
  constructor(sistema) {
    this.id = sistema?.id;
    this.attributes = sistema?.attributes;
    this.sistema = true;
    this.icon = 'circle text-xl';
    this.coordinates = [parseFloat(sistema?.attributes?.lat), parseFloat(sistema?.attributes?.lng)];
  }
}

export class RegistroFechamentoDescargaMapModel {
  constructor(registro, color) {
    this.id = registro?.id;
    this.name = registro?.attributes?.equ_nome;
    this.url =
      apiUrl +
      registro?.attributes?.detalhe_registrofechamento?.data?.attributes?.rf_kml?.data?.attributes
        ?.url;
    this.icon = 'data';
    this.color = color;
  }
}
