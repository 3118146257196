/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import './components/dependencies';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from './routes';
import { ToastContainer } from 'react-toastify';
import { Auth0Provider } from '@auth0/auth0-react';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Auth0Provider
      domain='dev-w2yxrq0jyq7govwj.us.auth0.com'
      clientId='kbHS1Q0wZOarwuPvRmaQe1egGTtvs9Es'
      redirectUri={window.location.origin ?? 'http://localhost:3000'}
    >
      <div className='App'>
        <BrowserRouter>
          <Routes>
            {routes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Routes>
          <ToastContainer
            position='bottom-center'
            autoClose={700}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='dark'
          />{' '}
        </BrowserRouter>
      </div>
    </Auth0Provider>
  );
}

export default App;
