import P from 'prop-types';
import React from 'react';

/**
 * @description Row component
 * @param {string} className - additional class name
 * @param {string} addClassName - additional class name
 * @param {object} style - style object additional
 *
 */
const BRow = React.forwardRef(function BRwo(props, ref) {
  const { addClassName, style, className } = props;
  return (
    <div
      ref={ref}
      className={`row ${addClassName || ''}${className ? ` ${className}` : ''}`}
      style={style}
    >
      {props.children}
    </div>
  );
});

export default BRow;
BRow.propTypes = {
  children: P.node,
  addClassName: P.string,
  className: P.string,
  style: P.object,
};
