import { Divider, Image } from 'antd';
import P from 'prop-types';
import L from 'leaflet';

import { apiUrl } from '../../../../config/modules/api';
import { Marker, Popup, Tooltip, useMap } from 'react-leaflet';
import { useContext, useEffect, useState } from 'react';
import { convertNome } from '../../../../utils/conversorTipoEquip';
import { HidrantIMG } from '../../../../myHooks/useHidrantImg';
import imgSistema from '../../../../layout/img/alfinete.png';
import { BtnModal, Modal, ModalBody, ModalFooter } from '../../../Modal';
import StreetView from './StreetView';
import { LayoutContext } from '../../../../config/context/LayoutContext';
import 'animate.css';

/**
 *
 * @param {Array} positions
 * @returns
 */
export const Markers = ({ positions }) => {
  const map = useMap();
  const [streetView, setStreetView] = useState(null);
  const ctxTheme = useContext(LayoutContext);
  const { themeDark } = ctxTheme;
  useEffect(() => {
    if (positions.length !== 0) {
      const bounds = L.latLngBounds(positions.map((obj) => obj.coordinates));
      // L.control.scale().addTo(map);

      if (bounds.isValid()) {
        // map.setZoomAround(bounds.getCenter(), map.getBoundsZoom(bounds) - 5);
        // map.fitBounds(bounds);
        map.flyToBounds(bounds, { animation: true, padding: [10, 10], duration: 0.5 });

        map.setMaxZoom(22);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions, map]);

  return positions.map((position, index) => {
    const fontAwesomeIcon = L.divIcon({
      html: position?.sistema
        ? `<img src='${imgSistema}' width='32' height='32' />`
        : `<i class="fas fa-${
            position?.tipo_equipamento?.icon
              ? position?.tipo_equipamento?.icon
              : position?.icon
              ? position?.icon
              : 'location-dot'
          }  p-0 ${position?.tipo_equipamento?.color ?? 'text-danger'}
         opacity-1 marker-icon " ></i>
         `,

      className: 'marker-icon',
      iconSize: [32, 32],
      iconAnchor: [10, 32],
      popupAnchor: [0, -32],
    });

    return (
      <div key={index}>
        <Marker
          position={position?.coordinates}
          icon={fontAwesomeIcon}
          className='animate__animated animate__fadeInDown animate__delay-05s'
        >
          {position?.unidade_remota && (
            <>
              <Popup>
                <div className='text-center'>
                  <h6>Unidade Remota</h6>
                  <p>
                    {position?.attributes?.title} <br /> N.º Serie {position?.attributes?.sn}
                    <br />
                    {position?.attributes?.tipo_remota?.data?.attributes?.title}
                  </p>
                  <p>
                    <span className='mt-1'>Coordenadas</span>
                    <span className='d-flex justify-content-between'>
                      <span className='text-center'>
                        lat: <code>{position?.coordinates[0]}</code>
                      </span>
                      <span className='text-center'>
                        lng: <code>{position?.coordinates[1]}</code>
                      </span>
                    </span>
                    <BtnModal
                      target={`streetView${position?.id}`}
                      color={'primary'}
                      addClassName={'btn-block'}
                      text={'Ver no StreetView'}
                      onClick={() => setStreetView(position)}
                    />
                    {/* <a
                    className='btn btn-primary btn-flat btn-block mt-2 text-light'
                    href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${
                      position?.coordinates?.lat && position?.coordinates?.lng
                        ? `${position?.coordinates?.lat},${position?.coordinates?.lng}`
                        : Array.isArray(position?.coordinates) &&
                          position?.coordinates.join(',').replace(',', '%2C')
                    }&heading=10&pitch=0&fov=80&hl=pt-br`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Ver no StreetView
                  </a> */}
                  </p>
                  <p>
                    Tipo {position?.attributes?.tipos_remota?.data?.attributes?.title}
                    <br />
                  </p>
                </div>
              </Popup>
              <Tooltip direction='bottom'>
                <span>Unidade Remota</span>
                <br />
                <span>{position?.attributes?.title}</span>
              </Tooltip>
            </>
          )}
          {!position?.sistema && !position?.unidade_remota && (
            <>
              <Popup>
                <div className='text-xs text-muted'>
                  ID:[{position?.id}]<br /> Identificador: {position?.attributes?.identificador}
                  <br />
                  Nome: {position?.attributes?.daee && <span>MEDIDOR - DAEE -</span>}{' '}
                  {position?.attributes?.captacao && <span> - CAPTAÇÃO -</span>}
                  {position?.attributes?.title} <br />
                  Tipo:{' '}
                  {convertNome(
                    position?.attributes?.tipos_equipamento?.data?.attributes?.tipo_nome,
                  )}
                  {position?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
                    'detalhe_hidrante' && (
                    <>
                      <br />
                      <span>
                        Tipo de Hidrante:{' '}
                        {position?.attributes?.detalhe_hidrante?.data?.attributes?.tipo}
                      </span>
                    </>
                  )}
                  <p>
                    <span className='mt-1'>Coordenadas</span>
                    <span className='d-flex justify-content-between'>
                      <span className='text-center'>
                        lat: <code>{position?.coordinates[0]}</code>
                      </span>
                      <span className='text-center'>
                        lng: <code>{position?.coordinates[1]}</code>
                      </span>
                    </span>
                    {/* {console.log({ COORDINATES_MARKES: position?.coordinates })} */}
                  </p>
                  <BtnModal
                    target={`streetView${position?.id}`}
                    color={'primary'}
                    addClassName={'btn-block'}
                    text={'Ver no StreetView'}
                    onClick={() => setStreetView(position)}
                  />
                  {/* <a
                    className='btn btn-primary btn-flat btn-block mt-2 text-light'
                    href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${
                      position?.coordinates?.lat && position?.coordinates?.lng
                        ? `${position?.coordinates?.lat},${position?.coordinates?.lng}`
                        : Array.isArray(position?.coordinates) &&
                          position?.coordinates.join(',').replace(',', '%2C')
                    }&heading=10&pitch=0&fov=80&hl=pt-br`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Ver no StreetView
                  </a> */}
                  <div className='col-12 d-flex justify-content-between align-items-center'>
                    <span>
                      Status:{' '}
                      {position?.attributes?.status ? (
                        <i className='fa fa-plus-circle text-success'></i>
                      ) : (
                        <i className='fa fa-minus-circle text-danger '></i>
                      )}
                    </span>
                    {position?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
                      'detalhe_macromedidor' &&
                      position?.attributes?.detalhe_macromedidor?.data?.attributes
                        ?.macro_tag_flux &&
                      position?.attributes?.detalhe_macromedidor?.data?.attributes
                        ?.macro_tag_leitura && (
                        <a
                          href={`/v1/painel-macro/id/${position?.id}`}
                          type='button'
                          className='btn btn-muted text-info text-sm'
                        >
                          {console.log(position?.attributes)}
                          <i className='fa fa-chart-line-up'></i>
                        </a>
                      )}
                    {position?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela ===
                      'reservatorio' &&
                      position?.attributes?.reservatorio?.data?.attributes?.volume_tagid &&
                      position?.attributes?.reservatorio?.data?.attributes?.nivel_percent_tagid &&
                      position?.attributes?.reservatorio?.data?.attributes?.nivel_m_tagid && (
                        <a
                          href={`/v1/painel-reservacao/id/${position?.id}`}
                          type='button'
                          className='btn btn-muted text-info text-sm'
                        >
                          {console.log(position?.attributes)}
                          <i className='fa fa-chart-line-up'></i>
                        </a>
                      )}
                    <a
                      href={position?.link}
                      type='button'
                      className='btn btn-muted text-info text-sm'
                    >
                      <i className='fa fa-eye'></i>
                    </a>
                  </div>
                </div>

                <div className='text-center'>
                  <Image
                    src={
                      position?.attributes?.tipos_equipamento?.data?.attributes?.tipo_tabela !==
                      'detalhe_hidrante'
                        ? apiUrl + position?.tipo_equipamento?.img
                        : HidrantIMG(position?.attributes?.detalhe_hidrante?.data?.attributes?.tipo)
                    }
                    alt={position?.attributes?.title}
                    width='auto'
                    height={'auto'}
                    style={{ maxHeight: 120 }}
                  />
                </div>
                {position?.tipo_equipamento?.tipo === 'Hidrante' && (
                  <p>
                    <a
                      href={position?.attributes?.detalhe_hidrante?.data?.attributes?.link_vistoria}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Link da Vistoria
                    </a>
                  </p>
                )}
              </Popup>
              <Tooltip direction='bottom'>
                <span>
                  {position?.attributes?.tipos_equipamento?.data?.attributes?.tipo_nome &&
                    `${convertNome(
                      position?.attributes?.tipos_equipamento?.data?.attributes?.tipo_nome,
                    )}`}
                </span>
                <br />
                <span>
                  {position?.attributes?.daee && <span>MEDIDOR - DAEE -</span>}{' '}
                  {position?.attributes?.captacao && <span> - CAPTAÇÃO -</span>}
                  {position?.attributes?.title}
                </span>
              </Tooltip>
            </>
          )}
          {position?.sistema && !position?.unidade_remota && (
            <>
              <Popup
                style={{ backgroundColor: themeDark ? '#343a40' : '' }}
                className={`bg-${themeDark ? 'dark' : 'light'}`}
              >
                <div className='text-center'>
                  <h6>Sistema / Localização</h6>
                  <p>{position?.attributes?.title}</p>
                  <p>
                    Equipamentos: {position?.attributes?.equipamentos?.data?.length}
                    <br />
                    Unidades remotas: {position?.attributes?.unidade_remotas?.data?.length}
                  </p>
                  <Divider />
                  <p>
                    {position?.coordinates.join(',')}
                    <br />
                    <BtnModal
                      target={`streetView${position?.id}`}
                      color={'primary'}
                      addClassName={'btn-block'}
                      text={'Ver no StreetView'}
                      onClick={() => setStreetView(position)}
                    />
                  </p>
                </div>
              </Popup>
              <Tooltip
                direction='bottom'
                permanent
                opacity={0.7}
                className={`bg-${themeDark ? 'dark' : 'light'} border-2`}
              >
                <span>{position?.attributes?.title}</span>
              </Tooltip>
            </>
          )}
        </Marker>

        {streetView && (
          <Modal id={`streetView${streetView?.id}`} size={'xxl'} fade title={'Street View'}>
            <ModalBody style={{ height: '70vh' }}>
              <StreetView
                coordinates={{
                  lat: streetView?.coordinates[0],
                  lng: streetView?.coordinates[1],
                }}
              />
            </ModalBody>
            <ModalFooter btnCloseTitle={'Fechar'} id={streetView?.id} />
          </Modal>
        )}
      </div>
    );
  });
};

Markers.propTypes = {
  positions: P.array.isRequired,
};
