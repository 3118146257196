import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { pagesMap } from '../config/pagesMap';

const useDinamicComponent = () => {
  const { slug } = useParams();
  const Element = slug ? (pagesMap[slug] ? pagesMap[slug] : pagesMap['404']) : pagesMap['home'];
  return (
    <Suspense fallback='Carregando...'>
      <Element />
    </Suspense>
  );
};

export default useDinamicComponent;
