import P from 'prop-types';

const If = (props) => {
  if (props.test || props?.condition) {
    return props.children;
  } else {
    return props?.else || false;
  }
};

export default If;
If.propTypes = {
  test: P.bool,
  condition: P.bool,
  children: P.node,
  else: P.node,
};
