import React from 'react';
import P from 'prop-types';
import { BRow } from '../AllComponents';

const ModalBody = (props) => {
  const { children, addClassName, style, className } = props;
  return (
    <div className={`modal-body${addClassName ? ` ${addClassName}` : ''} `}>
      <BRow style={style} className={className ? className : ''}>
        {children}
      </BRow>
    </div>
  );
};

export default ModalBody;
ModalBody.propTypes = {
  children: P.node,
  addClassName: P.string,
  className: P.string,
  style: P.object,
};
