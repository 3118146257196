import axios from 'axios';
import { geoJson } from 'leaflet';
import { useEffect, useMemo, useState } from 'react';
import { MapContainer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { LayersControlCustom } from './LayersControlCustom';

const ContentMapContainer = () => {
  const map = useMap();
  const [geoJsonData, setGeoJsonData] = useState([]);
  console.log({ geoJsonData });

  useEffect(() => {
    if (geoJsonData.length === 0) {
      getGeoBrasil();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function getGeoBrasil() {
    await axios
      .get('https://digisus.saude.gov.br/esusgestorgeo/rest/geojson/brasil')
      .then((response) => {
        setGeoJsonData(response.data);
      })
      .catch((e) => console.error(e));
  }
  useEffect(() => {
    if (geoJsonData.length !== 0) {
      const geoJsonMap = geoJson(geoJsonData).addTo(map);
      geoJsonMap.setStyle({
        color: '#000',
        fillColor: '#007ffc',
        weight: 1,
        fillOpacity: 0.2,
      });

      geoJsonMap.eachLayer((layer) => {
        layer.bindPopup(`<p class='text-center'>${layer.feature.properties.Local}</p>`);
      });
      map.fitBounds(geoJsonMap.getBounds());
    }
  }, [geoJsonData, map]);
};

const DefaultMap = ({ id }) => {
  return useMemo(() => {
    return (
      <MapContainer
        center={[-9.342761033979144, -48.219899999999996]}
        id={id}
        style={{ minHeight: '100%', width: '100%' }}
        zoomControl={false}
        minZoom={3}
        maxZoom={17}
      >
        <ContentMapContainer tileLayer={{ tile: 'GoogleMap', variants: 'GoogleSatellite' }} />
        <LayersControlCustom />
      </MapContainer>
    );
  }, [id]);
};

export default DefaultMap;
