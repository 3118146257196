import React, { useState } from 'react';
import P from 'prop-types';
import { Auth } from '../utils/auth/Auth';

const PrivateRouter = ({ children }) => {
  const auth = Auth();
  const [isAuthenticated] = useState(auth);

  return isAuthenticated ? <>{children}</> : window.location.replace('/login');
};

export default PrivateRouter;
PrivateRouter.propTypes = {
  children: P.any.isRequired,
};
