import React from 'react';
import P from 'prop-types';
const CardFooter = ({
  children,
  className,
  submitButtonShow,
  submitTextButton,
  cancelButtonShow,
  cancelTextButton,
}) => {
  return (
    <div className={`card-footer ${className}`}>
      {children}
      {(submitButtonShow || cancelButtonShow) && (
        <div className='d-flex justify-content-between'>
          {submitButtonShow && (
            <button type='submit' className='btn btn-info'>
              {submitTextButton ?? 'Cadastrar'}
            </button>
          )}
          {cancelButtonShow && (
            <button type='button' className='btn btn-secondary' data-dismiss='modal'>
              {cancelTextButton ?? 'Cancelar'}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

CardFooter.propTypes = {
  children: P.node,
  className: P.string,
  submitButtonShow: P.bool,
  cancelButtonShow: P.bool,
  submitTextButton: P.string,
  cancelTextButton: P.string,
};

export default CardFooter;
