import React, { Fragment, useContext, useState } from 'react';
import P from 'prop-types';
import { Ctx } from '../../../../store/ctx/Ctx';
import { Tooltip } from 'antd';

const FilterMap = ({ data }) => {
  const ctx = useContext(Ctx);
  const { tipoSelectedMap, setTipoSelectedMap } = ctx.props.data;
  const [allSelected, setAllSelected] = useState(false);

  const checkboxInput = document.querySelectorAll('.input-filters');
  const handleChange = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setTipoSelectedMap([...tipoSelectedMap, value]);
    } else {
      setTipoSelectedMap(tipoSelectedMap.filter((item) => item !== value));
    }
  };

  return (
    <>
      <div className='filter-map-item'>
        {data.length !== 0 &&
          data.map((item, i) => (
            <Fragment key={i}>
              <Tooltip title={item?.title} autoAdjustOverflow destroyTooltipOnHide>
                <label htmlFor={item?.type} className='btn btn-muted mt-1'>
                  <i
                    className={` fa fa-${
                      item?.type === 'detalhe_hidrante'
                        ? 'fire-hydrant'
                        : item?.icon
                        ? item?.icon
                        : 'location-dot'
                    } text-${item?.color}`}
                  ></i>
                </label>
                <input
                  key={i}
                  type='checkbox'
                  className='position-absolute d-none input-filters'
                  id={item?.type}
                  value={item?.type}
                  onChange={(e) => handleChange(e)}
                />
              </Tooltip>
            </Fragment>
          ))}
        <Tooltip title={'Selecionar TODOS'} autoAdjustOverflow destroyTooltipOnHide>
          <button
            type='button'
            htmlFor='all'
            className='btn btn-muted'
            onClick={() => {
              setAllSelected(true);
              setTipoSelectedMap(data.map((item) => item?.type));
              checkboxInput &&
                checkboxInput.forEach((item) => {
                  if (item.value) item.checked = true;
                });
            }}
          >
            <i className={`fa fa-circle text-${allSelected ? 'success' : 'muted'}`}></i>
          </button>
        </Tooltip>
        <Tooltip title={'Limpar'} autoAdjustOverflow destroyTooltipOnHide>
          <button
            type='button'
            htmlFor='reset'
            className='btn btn-muted'
            onClick={() => {
              setTipoSelectedMap([]);
              checkboxInput &&
                checkboxInput.forEach((item) => {
                  if (item.value) item.checked = false;
                });
            }}
          >
            <i className={`fa fa-empty-set text-danger`}></i>
          </button>
        </Tooltip>
      </div>
    </>
  );
};

FilterMap.propTypes = {
  data: P.array,
};

export default FilterMap;
