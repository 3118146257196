import React, { useContext, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../layout/img/icon_ecometria-wite.png';
import logoLight from '../../layout/img/favicon-1.png';
import './js/Treeview';
import { Nav, Navigation } from './nav';
import { bool } from 'prop-types';
import { AuthContext } from '../../config/context/AuthContext';
import { Image } from 'antd';
import { apiUrl } from '../../config/modules/api';
import { Logout } from '../../config/modules/helpers';
import { Ctx } from '../../store/ctx/Ctx';
import avatarDefault from '../../layout/img/user2-160x160.jpg';

const Sidebar = (props) => {
  const navigate = useNavigate();
  const navigation = Navigation();
  const ctxAuth = useContext(AuthContext);
  const companyData = useContext(Ctx);
  const { userData } = ctxAuth;

  return (
    <aside
      className={` main-sidebar ${
        props.themeDark ? ' sidebar-dark-info' : ' sidebar-light-info'
      } elevation-4 `}
    >
      {/* Brand Logo */}
      <Link to='/' className='brand-link primary-color '>
        <img
          src={
            props.themeDark
              ? (companyData[0]?.attributes.icon_dark?.data &&
                  apiUrl + companyData[0]?.attributes.icon_dark?.data?.attributes.url) ||
                logo
              : (companyData[0]?.attributes.icon?.data &&
                  apiUrl + companyData[0]?.attributes.icon?.data?.attributes.url) ||
                logoLight
          }
          alt='AdminLTE Logo'
          width={28}
          height={28}
          className='brand-image pl-2'
          style={{ opacity: '0.8' }}
        />

        <span
          className={`brand-text font-weight-light  ${props.themeDark ? 'primary-color' : ''}`}
          style={{ color: props.themeDark ? '' : '#84b014' }}
        >
          {(companyData[0]?.attributes.sidebar_logo?.data &&
            companyData[0]?.attributes.sidebar_logo_dark && (
              <img
                src={
                  props.themeDark
                    ? (companyData[0]?.attributes.icon_dark?.data &&
                        apiUrl +
                          companyData[0]?.attributes.sidebar_logo_dark?.data?.attributes.url) ||
                      logo
                    : (companyData[0]?.attributes.icon?.data &&
                        apiUrl + companyData[0]?.attributes.sidebar_logo?.data?.attributes.url) ||
                      logoLight
                }
                alt='AdminLTE Logo'
                width={'100%'}
                className='brand-image img-square '
                style={{ opacity: '0.8', maxHeight: '28px' }}
              />
            )) ||
            'ECOWEB'}
        </span>
      </Link>
      {/* Brand Logo end*/}
      <div className='sidebar'>
        {/* userPanel */}
        <div className='user-panel mt-3 pb-3 mb-3 '>
          <div className='image d-flex flex-wrap flex-column align-items-center justify-content-center'>
            <Image
              src={
                userData.avatar?.formats?.thumbnail?.url
                  ? apiUrl + userData.avatar?.formats?.thumbnail?.url
                  : userData.avatar?.url
                  ? apiUrl + userData.avatar.url
                  : avatarDefault
              }
              className='img-circle elevation-3 mr-2 '
              width={28}
              height={28}
              alt='User Image'
            />

            <div className='info'>
              <a className='text-light' href={`mailto:${userData.email}`}>
                {userData.email}
              </a>
            </div>
            <div className='info col-12'>
              <div className=' btn-group mt-3 col-12 d-flex justify-content-center'>
                <button
                  className='btn btn-info btn-sm'
                  onClick={() => navigate('/profile')}
                  title='Perfil'
                >
                  <i className='fa fa-user-circle'></i>
                </button>
                <button className='btn btn-danger btn-sm' onClick={() => Logout()}>
                  <i className='fa fa-sign-out-alt'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* userPanel */}
        {/* Sidebar */}

        {/* Sidebar Menu */}
        <nav className='mt-2'>
          <ul
            className='nav nav-pills nav-sidebar flex-column nav-child-indent text-sm pl-2'
            data-widget='treeview'
            role='menu'
            data-accordion='true'
          >
            {useMemo(() => {
              const sortedNav =
                navigation &&
                // eslint-disable-next-line array-callback-return
                navigation.sort((a, b) => {
                  a.atributes &&
                    b.atributes.title &&
                    a.atributes.title.localeCompare(b.atributes.title);
                });

              return (
                sortedNav &&
                sortedNav.map((nav) => {
                  return <Nav nav={nav} key={nav.id} />;
                })
              );
            }, [navigation])}
          </ul>

          <div className='sidebar-custom mt-3 pt-3 d-flex flex-wrap gap-3 justify-content-between'>
            <Link to='/profile' className='btn btn-link'>
              <i className='fal fa-cogs'></i> Config
            </Link>

            <Link to='/ajuda' className='btn btn-link hide-on-collapse pos-right'>
              <i className='fal fa-question-circle'></i> Help
            </Link>
          </div>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
};

export default Sidebar;
Sidebar.propTypes = {
  themeDark: bool,
};
