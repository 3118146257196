import axios from 'axios';
import env from './env';
import { GetCookies } from './helpers';
export const apiUrl = env('API_URL');
export const port = env('API_PORT') || '';
export const apiNodeUrl = `${env('SERVER_API_PROTOCOL')}://${env('SERVER_API_HOST')}${
  env('SERVER_API_PORT') ? `:${env('SERVER_API_PORT')}` : ''
}`;
export const version = env('SERVER_API_VERSION');

// const accessToken = GetCookies('io') || localStorage.getItem('io');
const userToken = GetCookies('tk_uac') || localStorage.getItem('tk_uac');

export const api = axios.create({
  baseURL: apiUrl,
});
export const apiNode = axios.create({
  baseURL: apiNodeUrl + `${version ? `/api/${version}` : '/api/'}`,
  headers: {
    Authorization: `Bearer ${userToken || localStorage.getItem('tk_uac')}`,
    'Content-Type': 'application/json', // Pode ser necessário ajustar o Content-Type conforme necessário
  },
});

export const ApiToken = env('API_TOKEN');
