const Footer = () => {
  return (
    <footer className='main-footer'>
      <div className='col-12'>
        <div className='row'>
          <div className='col-sm-6 text-center text-sm-left'>ImageLogo - copyright © 2021</div>
          <div className='col-sm-6 text-center text-sm-right'>contact - link</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
