import { api } from '../../../config/modules/api';

export async function UserCompany(id, token) {
  const response = await api.get('/api/companies', {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      'populate[0]': 'users_permissions_users',
      'populate[1]': 'user_groups',
      'filters[users_permissions_users][id][$eq]': id,
    },
  });
  try {
    if (response.data) {
      return response;
    }
  } catch (e) {
    throw new Error('Ocorreu um erro ao tentar localizar as permissões do Usuário');
  }
}
