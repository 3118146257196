import React from 'react';
import P from 'prop-types';

const BCol = (props) => {
  const { col, styles, className, addClassName, id } = props;

  const number = col ? col.toString()?.split(' ') : [12, 12, 12, 12];

  let classes = '';
  if (number[0]) classes += `col-sm-${number[0]}`;
  if (number[1]) classes += ` col-md-${number[1]}`;
  if (number[2]) classes += ` col-lg-${number[2]}`;
  if (number[3]) classes += ` col-xl-${number[3]}`;

  return (
    <div
      id={id || null}
      className={`${classes} ${addClassName ? ` ${addClassName}` : ''} ${
        className ? className : ''
      }`}
      style={styles ? styles : {}}
    >
      {props.children}
    </div>
  );
};

export default BCol;
BCol.propTypes = {
  id: P.string,
  children: P.node,
  col: P.oneOfType([P.string, P.number]),
  styles: P.object,
  addClassName: P.string,
  className: P.string,
};
