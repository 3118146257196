import P from 'prop-types'
import HeaderContent from './HeaderContent';
import React from 'react';
import { TemplateCtx } from '../store/contexts/TemplateContext';
const ContentWrapper = ({ children }) => {
  const TemplateContext = React.useContext(TemplateCtx);
  return <div className='content-wrapper d-flex flex-column'>
    {/* Header content */}
    <HeaderContent config={{ title: TemplateContext?.pageTitle, breadcrumbs: TemplateContext?.breadcrumbs }} >
      {TemplateContext?.filters?.length > 0 && TemplateContext?.filters?.map((Filter, i) => <div className={`col-sm-${i > 3 ? '6' : '3'} mb-3`} key={i}>{Filter}</div>)}
    </HeaderContent>
    {children}

  </div>;
};
export default ContentWrapper;
ContentWrapper.propTypes = {
  children: P.node
}
