import TesteSocket from '../pages/testes/TesteSocket';
import * as route from './routes';
export const pageMap = {
  ajuda: route.Wiki,
  'api-publica': route.ApiToken,
  'area-de-atuacao': route.AreaAtuacao,
  bairros: route.Bairros,
  captacao: route.Captacao,
  // clientes: route.Clientes,
  colaboradores: route.Colaboradores,
  company: route.Company,
  'contrato-telecom': route.ContractTelecom,
  'contrato-telemetria': route.ContratoTelemetria,
  'controle-de-qualidade': route.ControleQualidade,
  'comercial-medidores': route.MedidorCom,
  'comercial-proprietario': route.Proprietarios,
  'cliente-controle-de-qualidade': route.ClientControleQualidade,
  // conexoes: route.Conexoes,
  'daee-login': route.DaeeLogin,
  distritos: route.Distritos,
  'equipamento-group': route.EquipamentoGroup,
  equipamentos: route.Equipamentos,
  // exemplo: route.Exemplo,
  faq: route.Wiki,
  'gestao-daee': route.GestaoDaee,
  'gestao-de-abastecimento': route.GestaoAbastecimento,
  'gestao-de-calhas': route.GestaoCalhas,
  'gestao-medidor-vazao': route.GestaoMedidorVazao,
  'gestao-os': route.GestaoOs,
  'gestao-operacional': route.GestaoOperacional,
  'gestao-pluviometrico': route.GestaoPluviometrico,
  grupos: route.Grupos,
  help: route.Wiki,
  home: route.Home,
  imovel: route.Imoveis,
  // links: route.Links,
  linhas: route.Linhas,
  mananciais: route.Manancial,
  medidores: route.Medidores,
  // noticias: route.Noticias,
  operadora: route.Operadora,
  'ordem-de-servicos': route.OrdemServicos,
  'ordem-de-servicos-rc': route.OrdemServicoRC,
  'os-tipos-de-servicos': route.OSTiposServicos,
  outorgas: route.Outorgas,
  'planos-linhas': route.PlanoLinhas,
  // painelacessos: route.PainelAcessos,
  'painel-cliente': route.PainelCliente,
  'painel-controle': route.PainelControle,
  // painelgeral: route.PainelGeral,
  'painel-consumo': route.PainelConsumo,
  // painelsinaisanalogicos: route.PainelSinaisAnalogicos,
  painelvazoes: route.PainelVazoes,
  'parametros-de-controle-de-qualidade': route.QualityControlParameter,
  'painel-reservacao': route.PainelReservacao,
  perfil: route.Perfil,
  profile: route.Profile,
  'registro-fechamento': route.RegistroFechamento,
  'setor-do-colaborador': route.SetorColaborador,
  setores: route.Setores,
  'setores-de-abastecimento': route.SetoresAbastecimento,
  sistemas: route.Sistemas,
  teste: route.Teste,
  testesocket: TesteSocket,
  'tipo-captacao': route.TipoCaptacao,
  'tipo-de-reservatorio': route.TipoReservatorio,
  'tipo-ligacao': route.TipoLigacoes,
  'tipo-material': route.TipoMaterial,
  'tipos-de-manutencao': route.TipoManutencao,
  'tipos-medidores': route.TipoMedidores,
  'tipos-remota': route.TiposRemota,
  // todo: route.Todo,
  'unidades-de-medidas': route.UnidMedidas,
  'usuario-especialista': route.UserEspecialista,
  user: route.Usuarios,
  'gestao-de-vrps': route.GestaoVrps,
  'unidades-remotas': route.UnidadesRemotas,

  /* v1 */
  v1: route.V1,
};
