import React, { useEffect, useState } from 'react';
import P from 'prop-types';
import { api } from '../../config/modules/api';
import { Ctx } from '../ctx/Ctx';

// import { AuthContext } from '../../config/context/AuthContext';

const CompanyProvider = ({ children }) => {
  const [companie, setCompanie] = useState([]);

  //Pega nome da empresa
  // const url = document.URL;

  const urlCompanyName =
    document.URL.split('//')[1]
      .split(':')[0]
      .replace('www.', '')
      .replace('localhost', '')
      .replace('192.168.100.29', '')
      .split('.')[0] || 'ecoweb-dev';

  useEffect(() => {
    if (urlCompanyName) {
      getCompany(urlCompanyName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlCompanyName]);
  async function getCompany(company) {
    await api
      .get(`/api/companies`, {
        params: {
          'filters[company_ref][$eq]': company,
          'populate[0]': 'company_logo',
          'populate[1]': 'company_logo_dark',
          'populate[2]': 'icon',
          'populate[3]': 'icon_dark',
          'populate[4]': 'sidebar_logo',
          'populate[5]': 'sidebar_logo_dark',
        },
      })
      .then((res) => {
        if (res.data.data && res.data.data.length !== 0) {
          setCompanie(res.data.data);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  return <Ctx.Provider value={companie}>{children}</Ctx.Provider>;
};

export default CompanyProvider;
CompanyProvider.propTypes = {
  children: P.node,
};
