export const CONTENT_LOAD = 'CONTENT_LOAD';
export const CONTENT_SUCCESS = 'CONTENT_SUCCESS';
export const LOGIN_LOAD = 'LOGIN_LOAD';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const PERMISSIONS_FAIL = 'PERMISSIONS_FAIL';
export const PERMISSIONS_SUCCESS = 'PERMISSIONS_SUCCESS';
export const PERMISSIONS_LOAD = 'PERMISSIONS_LOAD';
export const LAYOUT_LOAD = 'LAYOUT_LOAD';
export const LAYOUT_SUCCESS = 'LAYOUT_SUCCESS';
export const PERFIL_SUCCESS = 'PERFIL_SUCCESS';
export const PERFIL_LOAD = 'PERFIL_LOAD';
export const PERFIL_FAIL = 'PERFIL_FAIL';
