import React from 'react';

import MapHome from './components/MapHome/MapHome';

const Dashboard = (props) => {
  return <MapHome />;
};

Dashboard.propTypes = {};

export default Dashboard;
