export default function env(env) {
  const data = {
    HOST: process.env.REACT_APP_HOST,
    API_PORT: process.env.REACT_APP_API_PORT,
    API_URL: process.env.REACT_APP_API_URL,
    API_TOKEN:
      process.env.REACT_APP_API_TOKEN ||
      '369a75369e9a7edb9d702c251f812a224d4ec25d4e6b719cb226327cf515251152a0bb1d11fb8daa00663ec81485d7bbcc55d7dc2a78361595a20bcd7c7433e7d36b9017b117f5d169d8c607758ce845f2753ffd6b7fffa631e6cc775f955115bf643729038b16792153c3a83023395e65d98eb10f36ff38c26d35c7571179a9',
    USER_TOKEN: process.env.REACT_APP_USER_TOKEN,
    SECRET_TOKEN: process.env.REACT_APP_SECRET_TOKEN,
    SECRET: process.env.REACT_APP_SECRET,
    SERVER_SECRET_KEY: process.env.REACT_APP_SERVER_SECRET_KEY,
    SERVER_SOCKET_PROTOCOL: process.env.REACT_APP_SERVER_SOCKET_PROTOCOL,
    SERVER_SOCKET_HOST: process.env.REACT_APP_SERVER_SOCKET_HOST,
    SERVER_SOCKET_PORT: process.env.REACT_APP_SERVER_SOCKET_PORT,
    SERVER_API_VERSION: process.env.REACT_APP_SERVER_API_VERSION,
    SERVER_API_PROTOCOL: process.env.REACT_APP_SERVER_API_PROTOCOL,
    SERVER_API_HOST: process.env.REACT_APP_SERVER_API_HOST,
    SERVER_API_PORT: process.env.REACT_APP_SERVER_API_PORT,
    AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
    AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    AUTH0_CLIENT_SECRET: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
    AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
    IQA_HOST: process.env.REACT_APP_IQA_HOST,
    IQA_USER: process.env.REACT_APP_IQA_USER,
    IQA_PASSWORD: process.env.REACT_APP_IQA_PASSWORD,
  };

  return data[env];
}
