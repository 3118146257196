import P from 'prop-types'
import Sidebar from '../../../components/Sidebar/sidebar';


export const Aside = ({ themeDark }) => {
  return <Sidebar themeDark={themeDark} />;
};
Aside.propTypes={
  themeDark: P.bool
}
