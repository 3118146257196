export const styleFunction = (feature, options) => {
  const styleUrl = feature?.properties.styleUrl;

  // Check if the styleUrl exists in the styleMapHash

  if (styleUrl) {
    const styleHash = feature.properties.styleHash;
    let strokes;
    let styleOption;
    if (options) {
      styleOption = options;
    }
    if (feature?.properties?.stroke) {
      strokes = feature.properties;
    }
    // Extract the color code from the styleHash
    const colorCode = styleHash.substr(-6);

    // Create a style object using the extracted color code
    return {
      fillColor: `#${colorCode}`,
      fillOpacity: styleOption?.fillOpacity,
      color: styleOption?.color,
      opacity: styleOption?.opacity,
      weight: styleOption?.weight,
      stroke: strokes ?? styleOption?.stroke,
    };
  } else if (feature?.properties.styleMapHash && feature.properties.styleMapHash.normal) {
    const styleHash = feature.properties.styleMapHash.normal;

    // Extract the color code from the styleHash
    const colorCode = styleHash.substr(-6);

    // Create a style object using the extracted color code
    return {
      fillColor: `#${colorCode}`,
      fillOpacity: 0.5,
      color: 'black',
      weight: 1,
    };
  }
  // Default style
  return {
    fillColor: 'blue',
    fillOpacity: 0.5,
    color: 'black',
    weight: 1,
  };
};
