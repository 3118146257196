export const userData = {
  userData: [],
};
export const groupData = {
  groupData: [],
};
export const contentData = {
  contentData: [],
};
export const layoutData = {
  layoutData: [],
};
export const perfilData = {
  checkboxElinitial: [],
};
export const perfilList = {
  perfilList: [],
};
