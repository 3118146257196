import React, { useEffect, useState } from 'react';
import P from 'prop-types';
import { useParams } from 'react-router-dom';
import { HomePageMap } from './HomePageMap';
import { Ctx } from '../../../store/ctx/Ctx';
import { getTipoEquipamentosPerNameRef } from './util/tipoEquipamentosPerNameRef';

const HomeProvider = ({ children, ctxAuth }) => {
  const { params } = useParams();
  const ComponentHome = params && HomePageMap[params] ? HomePageMap[params] : HomePageMap.Home;
  const adm =
    ctxAuth?.userData?.user_profile?.title === 'Administrador' ||
    ctxAuth?.userData?.user_profile?.adm;

  const [companySelected, setCompanySelected] = useState(
    !adm ? ctxAuth?.userData?.company?.id : '',
  );

  /* Setores */
  const [setorList, setSetorList] = useState([]);
  const [setorLoading, setSetorLoading] = useState(false);
  const [setorSelected, setSetorSelected] = useState('');
  const [captacoesList, setCaptacoesList] = useState([]);
  /* Distritos */
  const [distritoList, setDistritoList] = useState([]);
  const [loadDistrito, setLoadDistrito] = useState(false);
  const [distritoSelected, setDistritoSelected] = useState('');
  const [tipoSelectedMap, setTipoSelectedMap] = useState([]);
  const [imovelList, setImoveList] = useState([]);

  /* Sistemas*/
  const [sistemaList, setSistemaList] = useState([]);
  /* Equipamentos */
  const [equipamentoList, setEquipamentosList] = useState([]);
  const [tiposSelected, seetTiposSelected] = useState([]);
  /* Unidades Remotas */
  const [unidadeRemotaList, setUnidadeRemotaList] = useState([]);

  useEffect(() => {
    if (!adm) setCompanySelected(ctxAuth?.userData?.company?.id);
  }, [adm, ctxAuth?.userData?.company?.id]);
  /* Effects */
  // Setor List
  useEffect(() => {
    if (companySelected) {
      async function getSetorList() {
        setSetorLoading(true);
        await import('../../../config/modules/api').then(({ api }) => {
          api
            .get('/api/setores', {
              headers: {
                Authorization: `Bearer ${ctxAuth.token}`,
              },
              params: {
                sort: 'setor_nome:asc',
                paginaton: {
                  page: 1,
                  pageSize: 10000000000000,
                },

                'populate[1]': 'setor_kml',
                'filters[groups][company][id][$eq]': !isNaN(parseInt(companySelected))
                  ? parseInt(companySelected)
                  : null,
              },
            })
            .then((r) => setSetorList(r.data.data))
            .catch((e) => console.error(e))
            .finally(() => setSetorLoading(false));
        });
      }
      getSetorList();

      CaptacoesList();
      ImovelLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySelected, ctxAuth.token]);

  // Captações
  const CaptacoesList = async () => {
    await import('../../../config/modules/api').then(({ api }) => {
      api
        .get('/api/captacaos', {
          headers: { Authorization: `Bearer ${ctxAuth.token}` },
          params: {
            pagination: {
              page: 1,
              pageSize: 10000000000000,
            },
            'filters[company][id][$eq]': !isNaN(parseInt(companySelected))
              ? parseInt(companySelected)
              : null,
          },
        })
        .then((r) => setCaptacoesList(r?.data?.data))
        .catch((e) => console.error(e));
    });
  };
  const ImovelLists = async () => {
    await import('../../../config/modules/api').then(({ api }) => {
      api
        .get('/api/comercial-imovels', {
          headers: { Authorization: `Bearer ${ctxAuth.token}` },
          params: {
            'filters[company][id][$eq]': !isNaN(parseInt(companySelected))
              ? parseInt(companySelected)
              : null,
          },
        })
        .then((r) => setImoveList(r?.data?.data))
        .catch((e) => console.error(e));
    });
  };
  // Distrito List

  useEffect(() => {
    if (setorSelected) {
      async function getDistritoList() {
        setLoadDistrito(true);
        await import('../../../config/modules/api').then(({ api }) => {
          api
            .get('/api/distritos', {
              headers: {
                Authorization: `Bearer ${ctxAuth.token}`,
              },
              params: {
                sort: 'dmc_nome:asc',
                paginaton: {
                  page: 1,
                  pageSize: 10000000000000,
                },
                'populate[2]': 'dmc_kml',
                'filters[setor][groups][company][id][$eq]': !isNaN(parseInt(companySelected))
                  ? parseInt(companySelected)
                  : null,
                'filters[setor][id][$eq]': !isNaN(parseInt(setorSelected))
                  ? parseInt(setorSelected)
                  : null,
              },
            })
            .then((r) => setDistritoList(r.data.data))
            .catch((e) => console.error(e))
            .finally(() => setLoadDistrito(false));
        });
      }
      getDistritoList();
    } else {
      setDistritoList([]);
      setDistritoSelected('');
    }
  }, [companySelected, ctxAuth.token, setorSelected]);

  /* Sistemas */
  useEffect(() => {
    if (companySelected) {
      async function getSistemasList() {
        await import('../../../config/modules/api').then(({ api }) => {
          api
            .get('/api/sistemas', {
              headers: {
                Authorization: `Bearer ${ctxAuth.token}`,
              },
              params: {
                pagination: {
                  page: 1,
                  pageSize: 10000000000000,
                },
                'filters[lat][$ne]': '',
                'filters[lng][$ne]': '',
                'filters[company][id][$eq]': !isNaN(parseInt(companySelected))
                  ? parseInt(companySelected)
                  : null,
              },
            })
            .then((r) => setSistemaList(r.data.data))
            .catch((e) => console.error(e));
        });
      }
      getSistemasList();
    } else {
      setSistemaList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySelected]);

  useEffect(() => {
    if (tipoSelectedMap?.length !== 0) {
      getTipoEquipamentosPerNameRef(ctxAuth.token, tipoSelectedMap)
        .then((r) => {
          console.log(r);
          seetTiposSelected(r);
        })
        .catch((e) => console.error(e));
    } else {
      seetTiposSelected([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoSelectedMap]);

  // Equipamentos
  useEffect(() => {
    if ((companySelected && tiposSelected.length !== 0) || setorSelected || distritoSelected) {
      async function getEquipamentosList() {
        await import('../../../config/modules/api').then(({ api }) => {
          api
            .get('/api/equipamentos', {
              headers: {
                Authorization: `Bearer ${ctxAuth.token}`,
              },
              params: {
                'populate[tipos_equipamento][fields][0]': 'tipo_nome',
                'populate[tipos_equipamento][fields][1]': 'tipo_tabela',
                'populate[detalhe_hidrante][populate][fields]': 'tipo',
                'populate[detalhe_calha][populate][fields]': 'tipo',
                'populate[detalhe_macromedidor][populate][fields]': 'tipo',
                'populate[detalhe_pluviometro][populate][fields]': 'tipo',
                'populate[detalhe_registrodescarga][populate][fields]': 'tipo',
                'populate[detalhe_registrofechamento][populate][fields]': 'tipo',
                'populate[detalhe_transmissor_nivel][populate][fields]': 'tipo',
                'populate[detalhe_transmissor_pressao][populate][fields]': 'tipo',
                'populate[detalhe_va][populate][fields]': 'tipo',
                'populate[detalhe_vcn][populate][fields]': 'tipo',
                'populate[detalhe_vrp][populate][fields]': 'tipo',
                'populate[reservatorio][populate][fields]': 'tipo',
                'populate[tipos_equipamento][populate][tipo_img][populate]': '*',
                // ...populateEquipamento,
                pagination: {
                  page: 1,
                  pageSize: 1000000000000,
                },
                'filters[equ_lat][$nei]': '',
                'filters[equ_long][$nei]': '',
                'filters[tipos_equipamento][id][$in]': tiposSelected.map((t) => t.id),
                'filters[company][id][$eq]': !isNaN(parseInt(companySelected))
                  ? parseInt(companySelected)
                  : null,
                'filters[$and][0][distrito][setor][id][$eq]': !isNaN(parseInt(setorSelected))
                  ? parseInt(setorSelected)
                  : null,
                'filters[$and][0][distrito][id][$eq]': !isNaN(parseInt(distritoSelected))
                  ? parseInt(distritoSelected)
                  : null,
              },
            })
            .then((r) => {
              console.log(r, { EQUIPAMENTOS_MAP: r.data.data });
              setEquipamentosList(r.data.data);
            })
            .catch((e) => console.error(e));
        });
      }
      getEquipamentosList();
    } else {
      setEquipamentosList([]);
    }
  }, [companySelected, setorSelected, tiposSelected, distritoSelected, ctxAuth.token]);

  /* Unidades remotas */
  useEffect(() => {
    if (
      companySelected &&
      tipoSelectedMap.length !== 0 &&
      tipoSelectedMap.includes('unidade_remota')
    ) {
      async function getUnidadesRemotas() {
        await import('../../../config/modules/api').then(({ api }) => {
          api
            .get('/api/unidade-remotas', {
              headers: {
                Authorization: `Bearer ${ctxAuth.token}`,
              },
              params: {
                pagination: {
                  page: 1,
                  pageSize: 10000000000000,
                },
                'filters[lat][$nei]': '',
                'filters[long][$nei]': '',
                'filters[company][id][$eq]': !isNaN(parseInt(companySelected))
                  ? parseInt(companySelected)
                  : null,
              },
            })
            .then((r) => {
              console.log(r.data.data);
              setUnidadeRemotaList(r.data.data);
            })
            .catch((e) => console.error(e));
        });
      }
      getUnidadesRemotas();
    } else {
      setUnidadeRemotaList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySelected, tipoSelectedMap]);

  /* Effects */

  return (
    <Ctx.Provider
      value={
        <ComponentHome
          data={{
            equipamentoList,
            setorList,
            distritoList,
            loadDistrito,
            companySelected,
            setorSelected,
            distritoSelected,
            setorLoading,
            tipoSelectedMap,
            sistemaList,
            unidadeRemotaList,
            setTipoSelectedMap,
            setDistritoSelected,
            setCompanySelected,
            setSetorSelected,
            captacoesList,
            setCaptacoesList,
            imovelList,
            setImoveList,
          }}
          ctxAuth={{ ...ctxAuth, adm }}
        />
      }
    >
      {children}
    </Ctx.Provider>
  );
};

HomeProvider.propTypes = {
  children: P.node,
  ctxAuth: P.object,
};

export default HomeProvider;
