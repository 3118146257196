import React, { useContext, useMemo } from 'react';
import { Ctx } from '../../../../store/ctx/Ctx';
import { BCol, BContainer, BRow } from '../../../../components/AllComponents';
import FilterCompanies from '../../../../components/Filters/FilterCompany';
import FilterSelect from '../../../../components/Forms/FilterSelect/FilterSelect';
import FilterMap from './FilterMap';
import { iconColors } from '../../util/iconColors';

const HeaderHome = (props) => {
  const ctx = useContext(Ctx);
  const {
    tipoSelectedMap,

    companySelected,
    setorLoading,
    setCompanySelected,
    setSetorSelected,
    setorList,
    distritoList,
    loadDistrito,
    setDistritoSelected,
  } = ctx.props.data;
  const {
    adm,
    userData: { company },
  } = ctx.props.ctxAuth;
  const HeaderHomeContent = useMemo(() => {
    return (
      <>
        {/* <ContentHeader title={`DASHBOARD ${(!adm && company?.company_nome) || 'ECOWEB'}`} /> */}
        <BContainer fluid className={'mt-3'}>
          <BRow>
            {adm && (
              <BCol col={4} className={'mb-3'}>
                <FilterCompanies
                  onChange={setCompanySelected}
                  config={{ placeholder: 'Selecione uma empresa' }}
                />
              </BCol>
            )}

            <BCol col={adm ? 4 : 6} className={'mb-3'}>
              <FilterSelect
                onChange={setSetorSelected}
                loading={setorLoading}
                options={
                  setorList?.length !== 0 &&
                  setorList?.map((setor) => ({
                    label: setor?.attributes?.setor_nome,
                    value: setor?.id,
                  }))
                }
                config={{ placeholder: 'Selecione um Setor' }}
                emptyMessage={
                  !setorLoading && companySelected && setorList?.length === 0
                    ? 'Nenhum setor encontrado'
                    : !companySelected
                    ? 'Selecione uma empresa'
                    : ''
                }
              />
            </BCol>
            <BCol col={adm ? 4 : 6}>
              <FilterSelect
                onChange={setDistritoSelected}
                loading={loadDistrito}
                options={
                  distritoList?.length !== 0 &&
                  distritoList?.map((distrito) => ({
                    label: distrito?.attributes?.dmc_nome,
                    value: distrito?.id,
                  }))
                }
                config={{ placeholder: 'Selecione um Distrito' }}
                emptyMessage={
                  !setorLoading && companySelected && setorList?.length === 0
                    ? 'Nenhum Distrito encontrado'
                    : !companySelected && 'Selecione uma Setor'
                }
              />
            </BCol>
          </BRow>{' '}
          <BRow addClassName='position-relative'>
            <div className={`iconsFilter elevation-2 active`}>
              <div className='d-flex flex-wrap justify-content-center align-items-center '>
                <FilterMap data={iconColors(tipoSelectedMap)} />
              </div>
            </div>
          </BRow>
        </BContainer>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tipoSelectedMap,
    adm,
    company?.company_nome,
    setCompanySelected,
    setSetorSelected,
    setorLoading,
    setorList,
    companySelected,
    setDistritoSelected,
    loadDistrito,
    distritoList,
  ]);

  return HeaderHomeContent && HeaderHomeContent;
};

HeaderHome.propTypes = {};

export default HeaderHome;
