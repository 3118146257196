/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import P from 'prop-types';
import MenuTreeview from './menuTreeview';

const NavItems = (props) => {
  const [isMenuExtended, setIsMenuExtended] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const [isMainActive, setIsMainActive] = useState(false);
  const [isOneOfChildrenActive, setIsOneOfChildrenActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { onClick } = props;
  const toggleMenu = () => {
    setIsMenuExtended(!isMenuExtended);
  };
  // const body = document.querySelector('body');
  const handleMainMenuAction = () => {
    if (isExpandable) {
      toggleMenu();

      return;
    }
    navigate(props.link ? props.link : '/');
  };

  const calculateIsActive = (url) => {
    setIsMainActive(false);
    setIsOneOfChildrenActive(false);
    if (isExpandable && props && props.children) {
      props.children.forEach((item) => {
        if (item?.props.link === url.pathname) {
          setIsOneOfChildrenActive(true);
          setIsMenuExtended(true);
        }
      });
    } else if (props.link === url.pathname) {
      setIsMainActive(true);
    }
  };

  useEffect(() => {
    if (location) {
      calculateIsActive(location);
    }
  }, [location, isExpandable, props.link]);

  useEffect(() => {
    if (!isMainActive && !isOneOfChildrenActive) {
      setIsMenuExtended(false);
    }
  }, [isMainActive, isOneOfChildrenActive]);

  useEffect(() => {
    setIsExpandable(props && props.children && props.children.length > 0);
  }, [props]);

  return (
    <li
      className={`nav-item ${props.addClassName ? props.addClassName : ''}${
        isMenuExtended ? ' menu-open' : ''
      }`}
    >
      <Link
        to={props.link}
        className={`nav-link ${isMainActive || isOneOfChildrenActive ? 'active' : ''}`}
        role='link'
        title={props.titleLink}
        onClick={() => {
          handleMainMenuAction();
          onClick && onClick();
        }}
        style={{ cursor: 'pointer' }}
      >
        <i className={`fa-light fa-${props.icon} nav-icon`}></i>{' '}
        <p>
          {' '}
          {props.titleLink}
          {isExpandable && <i className='right fas fa-angle-left'></i>}
        </p>
      </Link>
      {/* {(props.link !== '/perfil' && (
        <Link
          to={props.link}
          className={`nav-link ${isMainActive || isOneOfChildrenActive ? 'active' : ''}`}
          role='link'
          title={props.titleLink}
          onClick={() => {
            handleMainMenuAction();
            onClick && onClick();
          }}
          style={{ cursor: 'pointer' }}
        >
          <i className={`fa-light fa-${props.icon} nav-icon`}></i>{' '}
          <p>
            {' '}
            {props.titleLink}
            {isExpandable && <i className='right fas fa-angle-left'></i>}
          </p>
        </Link>
      )) || (
        <a
          href={props.link}
          className={`nav-link ${isMainActive || isOneOfChildrenActive ? 'active' : ''}`}
          title={props.titleLink}
          onClick={() => {
            handleMainMenuAction();
            onClick && onClick();
          }}
          style={{ cursor: 'pointer' }}
        >
          <i className={`fa-light fa-${props.icon} nav-icon`}></i>{' '}
          <p>
            {' '}
            {props.titleLink}
            {isExpandable && <i className='right fas fa-angle-left'></i>}
          </p>
        </a>
      )} */}
      {isExpandable && <MenuTreeview>{props.children}</MenuTreeview>}
    </li>
  );
};

export default NavItems;
NavItems.propTypes = {
  link: P.string,
  titleLink: P.string,
  icon: P.string, // icon name,
  treeview: P.bool,
  children: P.node,
  addClassName: P.string,
  onClick: P.func,
};
