import React from 'react';
import P from 'prop-types';
import { useKeyValues } from '../../pages/testes/components/KeyValues/KeyValues';

const BtnModal = (props) => {
  useKeyValues();
  const {
    className,
    addClassName,
    target,
    color,
    toggle,
    children,
    icons,
    icon,
    iconColor,
    onClick,
    title,
    text,
    disabled,
    size,
    style,
    id,
  } = props;
  return (
    <button
      type='button'
      id={id}
      className={`btn btn-flat ${addClassName ? ` ${addClassName}` : ''} ${
        className ? ` ${className}` : ''
      } ${color ? ` btn-${color}` : ' btn-info'}${color === 'muted' ? ' text-dark' : ''}${
        icons && iconColor ? ` text-${iconColor}` : ''
      } ${size ? `btn-${size}` : ''}`}
      data-toggle={toggle ? toggle : 'modal'}
      data-target={`#${target}`}
      title={title}
      onClick={onClick}
      disabled={disabled}
      style={{ ...style }}
    >
      {icons || icon ? (
        <>
          <i
            className={`fas fa-${icons ?? icon}${
              (icons && iconColor) || (icon && iconColor) ? ` text-${iconColor}` : ''
            } `}
          ></i>{' '}
        </>
      ) : (
        ''
      )}
      <span>
        {text ? text : ''}
        {children}
      </span>
    </button>
  );
};

export default BtnModal;
BtnModal.propTypes = {
  addClassName: P.string,
  className: P.string,
  target: P.string.isRequired,
  toggle: P.string,
  children: P.node,
  icons: P.node,
  icon: P.node,
  color: P.string,
  title: P.string,
  onClick: P.func,
  iconColor: P.string,
  text: P.oneOfType([P.string, P.node, P.number]),
  disabled: P.bool,
  size: P.string,
  style: P.object,
  id: P.string,
};
