import P from 'prop-types'
import NavBar from '../../../components/Navbar';


const Header = ({ setThemeDark, themeDark }) => (
  <NavBar setThemeDark={setThemeDark} themeDark={themeDark} />
);

export default Header;

Header.propTypes = {
  setThemeDark: P.func,
  themeDark: P.bool
};
