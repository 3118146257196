import React, { useEffect, useState } from 'react';
import P from 'prop-types';

const Th = ({ label, setOrder, field, className, style }) => {
  const [orderList, setOrderList] = useState({});
  const [orderCliked, setOrderClicked] = useState(false);

  function handleOrder({ order, item }) {
    setOrderList({ order, item });
    setOrderClicked(!orderCliked);
  }
  useEffect(() => {
    if (orderList && field) setOrder(orderList?.order);
  }, [orderList, field, setOrder]);

  return (
    <th
      onClick={() =>
        field &&
        handleOrder({
          order: `${field}:${orderCliked ? 'desc' : 'asc'}`,
          item: field,
        })
      }
      style={{ cursor: setOrder ? 'pointer' : '', style }}
      className={className}
      title={label}
    >
      {label && label.toUpperCase()}{' '}
      {field && (
        <span className='text-xs'>{orderList?.item === field && orderCliked ? '▲' : '▼'}</span>
      )}
    </th>
  );
};

Th.propTypes = {
  label: P.string,
  setOrder: P.func,
  field: P.string,
  className: P.string,
  style: P.object,
};

export default Th;
