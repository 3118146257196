export const params = {
  'populate[0]': 'todos',
  'populate[1]': 'user_profile',
  'populate[2]': 'groups',
  'populate[3]': 'avatar',
  'populate[4]': 'equipamento',
  'populate[5]': 'company',
  'populate[6]': 'res_comentarios',
  'populate[7]': 'company_setors',
  'populate[8]': 'tipo_usuario',
  'populate[9]': 'so_area_atuacaos',
  'populate[10]': 'so_notes',
  'populate[11]': 'so_notes_response',
  'populate[12]': 'groups.users_permissions_users',
  'populate[13]': 'groups.clients',
  'populate[14]': 'groups.setors',
  'populate[15]': 'groups.connections',
  'populate[16]': 'groups.service_orders',
  'populate[17]': 'groups.company',
  'populate[18]': 'groups.equipamento',
  'populate[19]': 'user_profile.permissions_users',
  'populate[20]': 'user_profile.permcategories',
  'populate[21]': 'user_profile.user_roles',
  'populate[22]': 'user_profile.user_groups',
  'populate[23]': 'equipamento.detalhe_macromedidor',
  'populate[25]': 'equipamento.detalhe_micromedidor',
  'populate[26]': 'equipamento.distrito',
  'populate[27]': 'equipamento.detalhe_calha',
  'populate[28]': 'equipamento.detalhe_transmissor_pressao',
  'populate[29]': 'equipamento.detralhe_vrp',
  'populate[30]': 'equipamento.user_cad',
  'populate[31]': 'equipamento.connection',
  'populate[32]': 'equipamento.reservatorio',
  'populate[33]': 'equipamento.service_orders',
  'populate[34]': 'equipamento.company',
  'populate[35]': 'equipamento.user_groups',
  'populate[36]': 'company.company_logo',
  'populate[37]': 'company.company_logo_dark',
  'populate[38]': 'company.company_icon',
  'populate[39]': 'company.company_icon_dark',
  'populate[40]': 'company.sidebar_logo',
  'populate[41]': 'company.sidebar_logo_dark',
  'populate[42]': 'company.company_setors',
  'populate[43]': 'company.tipo_usuarios',
  'populate[44]': 'company.service_orders',
  'populate[45]': 'company.tipo_manutencaos',
  'populate[46]': 'company.equipamentos',
  'populate[47]': 'company.clients',
  'populate[48]': 'company.user_groups',
  'populate[49]': 'service_orders',
  'populate[50]': 'service_orders.equipamento',
  'populate[51]': 'service_orders.executor',
  'populate[52]': 'service_orders.servico_img',
  'populate[53]': 'service_orders.so_area_atuacao',
  'populate[54]': 'service_orders.user_groups',
  'populate[55]': 'service_orders.company',
  'populate[56]': 'service_orders.company_setor',
  'populate[57]': 'service_orders.so_notes',
  'populate[58]': 'so_notes.service_order',
  'populate[59]': 'so_notes.so_note_responses',
  'populate[60]': 'clients',
  'populate[61]': 'client_users',
  'populate[62]': 'public_api_tokens',
  'populate[63]': 'clients_cad',
  'populate[64]': 'comercial_imovels',
};
